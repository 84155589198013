/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import ProjectDetails from "../views/projects/ProjectDetails";
// import Projects from "../views/projects/Projects";
// import Users from "../views/users/Users";
// import ApprovalPending from "../views/projects/ApprovalPending";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";
import AdminFooter from "../components/Footers/AdminFooter";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import BreadcrumbLayout from '../layouts/BreadCrumb';
import routes from "../routes";
import { getFromLocalStorage } from '../shared/utility';
import * as actions from "../store/actions/index";
import BatchSummary from "../views/departments/BatchSummary";
import DepartmentIndex from '../views/departments/DepartmentIndex';
import DepartmentSummary from "../views/departments/DepartmentSummary";
import SODrilldown from "../views/traceability/SOTraceability";
import Certificates from "../views/certificates/Certificates"
import "./Admin.css";


class Admin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      departmentName: '',
      departmentId: ''
      // breadCrumbItems: [{ name: 'Departments', id: '1', url: '#' }]
    }
  }

  componentDidUpdate(e) {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // this.refs.mainContent.scrollTop = 0;
  }
  componentDidMount() {
    this.props.onTryAutoSignIn();
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    // alert(path)
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "";
  };
  handleSignOut = () => {
    this.props.onSignOut();
  };

  setDepartmentName = (dept = { name: '', id: '' }) => {
    this.setState({ departmentName: dept.name, departmentId: dept.id })
    // if (dept.name !== '') {
    //   this.setState(previousState => ({
    //     breadCrumbItems: [...previousState.breadCrumbItems, { name: dept.name, id: dept.id, url: '/admin/departments/' + dept.id }]
    //   }));
    // } else {
    //   this.setState(previousState => ({
    //     breadCrumbItems: [{ name: 'Departments', id: '1', url: '/admin/departments/index' }]
    //   }))
    // }

  }
  onChangeTab = (tab) => {
    this.setState(previousState => ({
      breadCrumbItems: [{ name: tab.name, id: '1', url: tab.url }]
    }))
  }
  goBack = (item) => {
    this.props.onFireBreadCrumb(item.deptName, item.batchName, item.batchId, item.batchNumber, item.data , item.deptId)
    this.props.history.push(item.url)

  }
  render() {
    if (
      (!this.props.isAuthenticated &&
        this.props.authRedirect !== "/auth/login") ||
      this.props.expiry < Math.floor(Date.now() / 1000)
    ) {
      this.props.authRedirect === "/auth/register"
        ? this.props.onSetAuthRedirectPath("/auth/register")
        : this.props.onSetAuthRedirectPath("/auth/login");
    }

    return (
      <>
        {this.props.authRedirect !== "/" ? (
          <Redirect to={this.props.authRedirect} />
        ) : null}
        <Sidebar
          {...this.props}
          logout={this.handleSignOut}
          //routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/logo.png"),
            imgAlt: "..."
          }}
        />
{/* ${window.location.pathname==='/admin/departments/index'?'bgCover':null} */}
        <>
          <div className="main-content margin-300" ref="mainContent">
            <AdminNavbar logout={this.handleSignOut} />
            <div className={`header py-2 bgCover`}>
              <Container>
                <div className="header-body text-center mb-5">
                  <Row className="justify-content-center mt-5">
                    <Col lg="5" md="6">
                    </Col>
                  </Row>
                </div>
              </Container>
              <div className="separator separator-bottom separator-skew">
              </div>
            </div>
            <Container fluid>
              <Row className="justify-content-center mx-3">
                <Container fluid>
                  <BreadcrumbLayout items={this.props.breadCrumbItems} goBack={this.goBack} />
                </Container>
                <Switch>
                  <Route exact strict
                    path="/admin/departments/:department_id/batches/:batch_id"
                    render={(props) => <BatchSummary {...props}
                      departmentName={this.state.departmentName} />} />
                  <Route
                    path="/admin/departments/index"
                    render={(props) => <DepartmentIndex {...props}
                      setDepartmentName={this.setDepartmentName} />} />
                  <Route exact strict
                    path="/admin/departments/:department_id"
                    render={(props) => <DepartmentSummary {...props}
                      departmentName={this.state.departmentName} />} />
                  <Route
                    path="/admin/traceability/drilldown"
                    render={(props) => <SODrilldown {...props}
                      departmentName={this.state.departmentName}
                    />} />
                    <Route
                    path="/admin/certificates/document"
                    render={(props) => <Certificates {...props}
                      departmentName={this.state.departmentName}
                    />} />
                  {/* <Redirect from="/admin" to="/admin/departments/index" /> */}
                  {/* <Redirect from="/admin" to={this.props.landingPage}/> */}
                                {
                                    getFromLocalStorage("user_type").toLowerCase() === "customer" ?
                                        <Redirect from="/admin" to="/admin/traceability/drilldown" />
                                        :
                                        <Redirect from="/admin" to="/admin/departments/index" />
                                        // <Redirect from="/admin" to="/admin/certificates"/>

                                }
                </Switch>
              </Row>

            </Container>
            <AdminFooter />

          </div>
        </>







        {/* <div className="main-content bg-white" ref="mainContent">
          <AdminNavbar
            {...this.props}
            logout={this.handleSignOut}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Container className="pt-8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                <Route path="/admin/departments" component={DepartmentSummary} />
              </Switch>
            </Row>
          </Container>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div> */}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.email !== null,
  authRedirect: state.auth.authRedirectPath,
  expiry: state.auth.expiry,
  allDepartments: state.departments.allDepartments,
  role: state.auth.role,
  breadCrumbItems: state.departments.breadCrumbItems,
  landingPage: state.auth.landingPage
});
const mapDispatchToProps = dispatch => ({
  onSignOut: () => dispatch(actions.signOut()),
  onSetAuthRedirectPath: route => dispatch(actions.setAuthRedirectPath(route)),
  onTryAutoSignIn: () => dispatch(actions.checkAuthState()),
  onInit: () => dispatch(actions.init()),
  onFireBreadCrumb: (deptName, batchName, batchId, batchNumber, data , deptId) => dispatch(actions.breadCrumb(deptName, batchName, batchId, batchNumber, data , deptId))

});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);

