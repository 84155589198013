import React, { Component } from 'react';
import { Col, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap';
import Validators from './Validations';
export default class UploadDocModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: null,
      uploadFileName: '',
      documentDescription: '',
      uploadFileNameTouched: false,
      uploadFileNameOkay: false,
      documentDescriptionTouched: false,
      documentDescriptionOkay: false,
      enableSubmit: false,
    };
    this.validators = {
      uploadFileName: {
        validators: value => Validators.fieldRequired(value),
        errorMessage: 'Document not selected'
      },
      documentDescription: {
        validators: value => Validators.fieldRequired(value),
        errorMessage: 'Description cannot be blank or less than 3 characters'
      }
    };
  }

  handleEnableSubmit = () =>
    this.state.uploadFileNameTouched &&
    this.state.uploadFileNameOkay &&
    this.state.documentDescriptionTouched &&
    this.state.documentDescriptionOkay;

  inputChangeHandler = (event, controlName) => {
    this.setState(
      {
        [controlName]: event.target.value,
        [`${controlName}Touched`]: true,
        [`${controlName}Okay`]: this.validators[controlName].validators(
          event.target.value
        )
      },
      () => {
        this.setState({ enableSubmit: this.handleEnableSubmit() });
        this.props.trackDocumentUpload({
            state: this.state,
            index: this.props.index,
            submit: this.handleEnableSubmit()
        });
      }
    );
  };

  getFile = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState(
        {
          file: file,
          imagePreviewUrl: reader.result,
          uploadFileName: file.name,
          uploadFileNameTouched: true,
          uploadFileNameOkay: this.validators.uploadFileName.validators(
            file.name
          )
        },
        () => {
          this.props.trackDocumentUpload({
              state: this.state,
              index: this.props.index,
              submit: this.handleEnableSubmit()
          });
          this.setState({ enableSubmit: this.handleEnableSubmit() });
        }
      );
    };
  };
  trackOriginalDocStatus = () => {
    this.setState({ originalDocumentListModified: true });
  };


  render() {
    return (
        
      <div className='border theme-border-color rounded p-2 mt-4'>
        
        <Label className='h4'>Add Document</Label>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={() => {
            this.props.removeDocAddWindow(this.props.index)
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
        <FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <CustomInput
                  type='file'
                  id='exampleCustomFileBrowser'
                  label={this.props.fileDetails &&
                    this.props.fileDetails.uploadFileName? this.props.fileDetails.uploadFileName:
                    this.state.uploadFileName
                      ? this.state.uploadFileName
                      : 'Choose Document'
                  }
                  name='customFile'
                  onChange={this.getFile}
                />
                {this.state.uploadFileNameTouched &&
                !this.state.uploadFileNameOkay ? (
                  <span className='text-danger'>
                    <small>{this.validators.uploadFileName.errorMessage}</small>
                  </span>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col colSpan={12}>
              <FormGroup>
                <Label for='doc_desc' className='h4'>
                  Document Description
                </Label>
                <Input
                  className='border theme-border-color rounded p-2'
                  type='textarea'
                  autoComplete='off'
                  name='doc_desc'
                  placeholder='Enter Description'
                  value={this.props.fileDetails &&
                    this.props.fileDetails.documentDescription?
                    this.props.fileDetails.documentDescription:
                    this.state.documentDescription
                      ? this.state.documentDescription
                      : ''
                  }
                  onChange={e => {
                    this.inputChangeHandler(e, 'documentDescription');
                  }}
                ></Input>
                {this.state.documentDescriptionTouched &&
                !this.state.documentDescriptionOkay ? (
                  <span className='text-danger'>
                    <small>
                      {this.validators.documentDescription.errorMessage}
                    </small>
                  </span>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
          </Row>
        </FormGroup>
      </div>
    );
  }
}
