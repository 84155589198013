import warehouse from '../assets/img/icons/departments/customer/cotton_dept.png';
import cut_sew_dept from '../assets/img/icons/departments/customer/cut_sew_dept.png';
import greige_fabric from '../assets/img/icons/departments/greige_fabric.png';
import processing_dept from '../assets/img/icons/departments/customer/processing_dept.png';
import spinning_dept from '../assets/img/icons/departments/customer/spinning_dept.png';
import weaving_dept from '../assets/img/icons/departments/customer/weaving_dept.png';
// import yarn_dept from '../assets/img/icons/departments/customer/yarn_dept.png';

const getAssetImage = (string) => {
    switch (string) {
        case 'cotton_warehouse':
            return warehouse;
        case 'spinning':
            return spinning_dept;
        case 'weaving':
            return weaving_dept;
        case 'yarn_warehouse':
            return warehouse;
        case 'greige_fabric_storage':
            return greige_fabric;
        case 'processing':
            return processing_dept;
        case 'cut_and_sew':
            return cut_sew_dept;
        default:
            break;
    }
}
export default getAssetImage