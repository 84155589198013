import React from 'react';
import './Loader.css';
import WelspunLoader from '../../assets/img/icons/loader.gif';
export default function Loader(props) {
  return (
    <React.Fragment>
      <div className='row'>
        
        <img src={WelspunLoader} className="mr-2" />
        <span className='h2 text-default text-capitalize mt-2'>
          {props.text || 'Loading'}
        </span>{' '}
        {/* <div
          className=' ml-3 dot-pulse d-inline'
          style={{ marginTop: '10px' }}
        ></div> */}
      </div>
    </React.Fragment>
  );
}
