// import Maps from "./views/examples/Maps";
// import Tables from "./views/examples/Tables";
// import Icons from "./views/examples/Icons";
// import Projects from "./views/projects/Projects";
// import Users from "./views/users/Users";
// import ApprovalPending from './views/projects/ApprovalPending'
var DefaultRoutes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // },
  // {
  //   path: "/projects",
  //   name: "Projects",
  //   icon: "ni ni-circle-08 text-blue",
  //   component: Projects,
  //   layout: "/admin"
  // },
  // {
  //   path: "/users",
  //   name: "Users",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Users,
  //   layout: "/admin"
  // },
  // {
  //   path: "/project-accordian",
  //   name: "Project Accordian",
  //   icon: "ni ni-key-25 text-info",
  //   component: ProjectAccordian,
  //   layout: "/admin"
  // },
  // {
  //   path: "/projects",
  //   name: "Projects",
  //   icon: "ni ni-circle-08 text-blue",
  //   component: Projects,
  //   layout: "/admin"
  // }, {
  //   path: "/approval-pending",
  //   name: "Approval Pending",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: ApprovalPending,
  //   layout: "/admin"
  // },
  // {
  //   path: "/project-details/:id",
  //   name: "Project Details",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: ProjectDetails,
  //   layout: "/admin"
  // },
];
export default DefaultRoutes;
