import React, {useState} from 'react'
import {InputGroup, InputGroupAddon, InputGroupText, Input} from 'reactstrap';
export default function SearchBar(props) {
  let { searchArray, updateDisplayList, placeholderText, searchColumn } = props;
  const [searchText, setSearchText] = useState("");

  const searchFilterFunction = (targetArray, targetColumns, searchText) => {return targetArray.filter(row => {var truthyResult = targetColumns.reduce((acc, curValue) => {return acc || checkIfTrue(row, curValue, searchText);}, false);if (truthyResult) return row;});};

  const searchByText = value => {
    setSearchText(value);
    if (value) {
      updateDisplayList(searchFilterFunction(searchArray, searchColumn, value));
    } else {
      updateDisplayList(searchArray);
    }
  };

  return (
    <InputGroup className="input-group-alternative border-style border-0">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <span className="fa fa-search icon-color-size" />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        autoComplete="off"
        placeholder={`Search by ${placeholderText ? placeholderText : "Batch"}`}
        type="text"
        value={searchText}
        onChange={e => searchByText(e.target.value)}
      />
    </InputGroup>
  );

  function checkIfTrue(row, targetColumn, searchText) {return row[targetColumn].toString().toLowerCase().includes(searchText.toLowerCase()) ? true : false;}}
