import * as actionTypes from '../actions/action-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  success: null,
  loading: false,
  errors: null,
  msg: null,
  documents: []
};

const getCertificateDocumentsStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    success: null,
    msg: null,
    documents: []
  });
};
const getCertificateDocumentsSuccess = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: false,
    success: true,
    msg: null,
    documents: action.documents
  });
};

const getCertificateDocumentsFail = (state, action) => {
  return updateObject(state, {
    errors: true,
    msg: action.error.message,
    loading: false,
    success: false,
    documents: []
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CERTIFICATE_DOCUMENTS_START:
      return getCertificateDocumentsStart(state, action);
    case actionTypes.GET_CERTIFICATE_DOCUMENTS_SUCCESS:
      return getCertificateDocumentsSuccess(state, action);
    case actionTypes.GET_CERTIFICATE_DOCUMENTS_FAIL:
      return getCertificateDocumentsFail(state, action);

    default:
      return state;
  }
};
