import React, { Component } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
  Table
} from 'reactstrap';
import Error from '../../shared/Error/Error';
import Loader from '../../shared/Loader/Loader';
import Paginate from '../../shared/Pagination/Paginate';
import UploadDocModule from '../../shared/UploadDocModule';
import { messageList, permissionsManager } from '../../shared/utility';
import Validators from '../../shared/Validations';
import * as actions from '../../store/actions/index';
import './BatchSummary.css';
import { downloadDocument } from '../../shared/Services';

class BatchSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExternalReportModalOpen: false,
      addMoreClicked: false,
      internalReport: {},
      reportName: '',
      documentList: [],
      fileUploadList: [],
      addDocumentRenderer: [],
      documentSubmitArray: [],
      currentPage: 1,
      postsPerPage: 10,
      pageCount: 0,
      currentBatches: [],
      batchSummary: {},
      table_data: [],
      activityName: '',
      warningModalOpen: false,
      activityDes: '',
      activityNameOkay: false,
      activityDesOkay: false,
      activityNameTouched: false,
      activityDesTouched: false,
      toggleDocModal: false,
      isWorking: false,
      enableSubmit: false,
      enableUpdate: false,
      batchApprovalWorking: false
    };
    this.validators = {
      activityName: {
        validators: value => Validators.fieldRequired(value),
        errorMessage: 'Name field cannot be blank or less than 3 characters'
      },
      activityDes: {
        validators: value => Validators.fieldRequired(value),
        errorMessage: 'Description Cannot be blank or less than 3 characters'
      },
      activityStDate: {
        validators: value => Validators.fieldIsDate(value),
        errorMessage: 'Invalid Date'
      }
    };
  }

  componentDidMount() {
    this.props.onGetBatchSummary(
      this.props.match.params.batch_id,
      this.props.match.params.department_id
    );
  }

  componentDidUpdate() {
    if (
      this.state.isWorking &&
      !this.props.activityLoading &&
      !this.props.activityError
    ) {
      this.toggleModal();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let indexOfLastPost = prevState.currentPage * prevState.postsPerPage;
    let indexOfFirstPost = indexOfLastPost - prevState.postsPerPage;
    if (
      nextProps.batchSummary &&
      nextProps.batchSummary.upstream_batches &&
      prevState.batchSummary !== nextProps.batchSummary
    ) {
      return {
        pageCount: Math.ceil(
          nextProps.batchSummary.upstream_batches.length /
            prevState.postsPerPage
        ),
        batchSummary: nextProps.batchSummary,
        table_data: nextProps.batchSummary.upstream_batches,
        currentBatches: nextProps.batchSummary.upstream_batches.slice(
          indexOfFirstPost,
          indexOfLastPost
        )
      };
    } else return {};
  }

  toggleWarningModal = () => {
    this.setState({ warningModalOpen: !this.state.warningModalOpen });
  };

  removeDocAddWindow = index => {
    let tempDocAddRenderer = this.state.addDocumentRenderer
      .slice()
      .filter((item, i) => i !== index);
    let tempDocSubmitArray = this.state.documentSubmitArray
      .slice()
      .filter((item, i) => i !== index);
    let tempFileUploadList = this.state.fileUploadList
      .slice()
      .filter((item, i) => i !== index);

    this.setState(
      {
        addDocumentRenderer: tempDocAddRenderer,
        documentSubmitArray: tempDocSubmitArray,
        fileUploadList: tempFileUploadList
      },
      () => {
        this.setState({
          enableSubmit: this.handleEnableSubmit(),
          enableUpdate: this.handleEnableUpdate()
        });
      }
    );
  };

  paginate = pageNumber => {
    this.setState(
      {
        currentPage: pageNumber.selected ? pageNumber.selected + 1 : pageNumber
      },
      () => {
        let indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        let indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        this.setState({
          currentBatches: this.state.table_data.slice(
            indexOfFirstPost,
            indexOfLastPost
          )
        });
      }
    );
  };

  resetForm = () => {
    this.setState({
      activePhase: '',
      isWorking: false,
      fileUploadList: [],
      addDocumentRenderer: [],
      documentSubmitArray: [],
      originalDocuments: [],
      docForDelete: [],
      activityName: '',
      activityDes: '',
      activityNameOkay: false,
      activityDesOkay: false,
      activityNameTouched: false,
      activityDesTouched: false,
      enableSubmit: false,
      enableUpdate: false,
      enableAddDocSubmit: false,
      addDocumentWorking: false,
      originalDocumentListModified: false,
      tempSelectedDocument: {},
      isApproveWorking: false
    });
  };

  enableDocSubmit = () => {
    if (
      Object.keys(this.state.selectedActivity).length !== 0 &&
      this.state.selectedActivity.constructor === Object &&
      this.state.selectedActivity.documents.length &&
      this.state.selectedActivity.documents.length !==
        this.state.originalDocuments.length
    ) {
      if (this.state.documentSubmitArray.length) {
        return this.state.documentSubmitArray.reduce(
          (total = true, amount) => total && amount
        );
      } else return true;
    } else {
      if (this.state.documentSubmitArray.length) {
        return this.state.documentSubmitArray.reduce(
          (total = true, amount) => total && amount
        );
      } else return false;
    }
  };

  handleEnableSubmit = () => {
    return this.state.activityNameTouched &&
      this.state.activityNameOkay &&
      // this.state.activityDesTouched &&
      // this.state.activityDesOkay &&
      this.state.documentSubmitArray.length
      ? this.state.documentSubmitArray.reduce(
          (initialValue = true, nextValue) => initialValue && nextValue
        )
      : false;
  };

  handleEnableUpdate = () => {
    return this.state.documentSubmitArray.length
      ? this.state.documentSubmitArray.reduce(
          (initialValue = true, nextValue) => initialValue && nextValue
        )
      : false;
  };

  inputChangeHandler = (event, controlName) => {
    this.setState(
      {
        [controlName]: event.target.value,
        [`${controlName}Touched`]: true,
        [`${controlName}Okay`]: this.validators[controlName].validators(
          event.target.value
        )
      },
      () =>
        this.setState({
          enableSubmit: this.handleEnableSubmit(),
          enableUpdate: this.handleEnableUpdate()
        })
    );
  };

  handleAddDocument = e => {
    e.preventDefault();
    let tempDocSubmitArray = this.state.documentSubmitArray;
    tempDocSubmitArray.push(false);
    let tempDocAddRenderer = this.state.addDocumentRenderer;
    tempDocAddRenderer.push(`_${this.state.addDocumentRenderer.length + 1}`);
    this.setState({
      addDocumentRenderer: tempDocAddRenderer,
      documentSubmitArray: tempDocSubmitArray,
      enableSubmit: false,
      enableUpdate: false
    });
  };
  toggleModal = (name = '', documentList = []) => {
    this.setState({
      toggleDocModal: !this.state.toggleDocModal
    });
    this.resetForm();
  };

  toggleShowAddDoc = () => {
    this.setState({ addMoreClicked: !this.state.addMoreClicked });
  };

  trackDocumentUpload = data => {
    let tempDocSubmitArray = this.state.documentSubmitArray;
    let tempFileUploadList = this.state.fileUploadList;
    tempDocSubmitArray[data.index] = data.submit;
    tempFileUploadList[data.index] = data.state;
    this.setState(
      {
        fileUploadList: tempFileUploadList,
        documentSubmitArray: tempDocSubmitArray
      },
      () => {
        this.setState({
          enableSubmit: this.handleEnableSubmit(),
          enableUpdate: this.handleEnableUpdate()
        });
      }
    );
  };

  handleClick = (id, data) => {
    this.props.onGetBatchSummary(data['id'], id);
    this.props.history.push(`/admin/departments/${id}/batches/${data['id']}`);
  };

  handleSubmit = () => {
    this.setState({ isWorking: true });
    let tempFileUploadList = [];
    this.state.fileUploadList.forEach(item => {
      tempFileUploadList.push({
        name: item.uploadFileName.replace('.PDF', '.pdf'),
        description: item.documentDescription,
        file_name: item.uploadFileName.replace('.PDF', '.pdf'),
        file: item.imagePreviewUrl.split(',')[1]
      });
    });

    let payload = {
      name: this.state.activityName,
      description: this.state.activityDes,
      type: 'Testing Report',
      metadata: {
        key: 'Value2'
      },
      is_visible: true,
      documents: tempFileUploadList
    };
    this.props.onAddNewActivity(this.props.match.params.batch_id, payload);
  };

  render() {
    let { currentBatches } = this.state;
    let {
      batchSummary,
      batchSuccess,
      batchLoading,
      batchMsg,
      batchError,
      batchApprovalLoading,
      batchApprovalError
    } = this.props;
    let disable_approval_button = false;
    if (batchSummary.upstream_batches && batchSummary.upstream_batches.length) {
      disable_approval_button = batchSummary.upstream_batches.reduce(
        (final, initial) => {
          if (initial.approval_status.toString() === 'pending') {
            return true;
          } else {
            return false;
          }
        },
        true
      );
    }
    let externalActivities = [];
    let internalActivities = [];
    let subStages = [];
    if (batchSummary && Object.keys(batchSummary).length) {
      batchSummary.activities.map((activity, index) => {
        if (activity.is_visible) {
          switch (activity.type) {
            case 'Sub Stage':
              subStages.push(activity);
              return null;
            case 'Testing Report':
              externalActivities.push(activity);
              return null;
            case 'Other':
              internalActivities.push(activity);
              return null;
            default:
              return null;
          }
        }
      });
    }
    this.toggleReportModal = (reportType, reportName = '', data = {}) => {
      switch (reportType) {
        case 'internal':
          this.setState({
            toggleDocModal: !this.state.toggleDocModal,
            reportType,
            internalReport: data,
            reportName
          });
          break;
        case 'external':
          this.setState({
            toggleDocModal: !this.state.toggleDocModal,
            reportType,
            documentList: data,
            reportName
          });
          break;
        case 'new':
          this.setState({
            toggleDocModal: !this.state.toggleDocMocal,
            reportName: 'Add New Documents',
            reportType
          });
          break;
        default:
          break;
      }
    };

    this.approveBatch = () => {
      this.setState({ isApproveWorking: true, warningModalOpen: false });
      this.props.onApproveBatch(
        batchSummary.batch_number,
        this.props.match.params.department_id,
        this.props.match.params.batch_id
      );
    };
    console.log(batchSummary);
    return (
      <React.Fragment>
        <Container fluid className='pb-5'>
          <div>
            {batchLoading && !batchSuccess ? (
              <Loader />
            ) : !batchLoading && batchError ? (
              <Error message={`Error! ${batchMsg}`} />
            ) : !batchLoading && batchSuccess ? (
              batchSummary && Object.keys(batchSummary).length ? (
                <>
                  <Row>
                    <Col className='col-md-8 col-sm-12 col-12'>
                      <span className='h1 d-block text-left'>
                        {batchSummary.stage && batchSummary.stage.name
                          ? batchSummary.stage.name
                          : `Batch No`}
                        :{' '}
                        <span className='font-weight-normal'>
                          {batchSummary.batch_number}
                        </span>
                      </span>
                    </Col>
                    <Col className='col-md-4 col-sm-12 col-12'>
                      <span className='mr-auto d-block h1'>
                        Status:{' '}
                        <span className='font-weight-normal text-capitalize'>
                          {batchSummary.approval_status
                            ? batchSummary.approval_status
                            : ''}
                        </span>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='col-lg-5 col-md-12 col-12 mr-auto'>
                      <span className='h1 d-block text-left'>
                        Department:
                        <span className='font-weight-normal'>
                          {batchSummary.department.name}
                        </span>
                      </span>
                    </Col>
                    <Col className='col-lg-7 col-md-12 col-12 ml-auto'>
                      {batchSummary.approval_status.toLowerCase() ===
                        'pending' &&
                      permissionsManager(batchSummary.role.toLowerCase()) >
                        2 ? (
                        <>
                          <Button
                            color='primary'
                            className='btn-border-style-outline m-0'
                            type='button'
                            disabled={
                              batchApprovalLoading || disable_approval_button
                            }
                            onClick={this.toggleWarningModal}
                          >
                            APPROVE{' '}
                            {batchSummary.stage && batchSummary.stage.name
                              ? batchSummary.stage.name.toUpperCase()
                              : 'BATCH'}
                          </Button>{' '}
                          {batchApprovalLoading ? (
                            <span className='h5'>
                              <Loader text='Please Wait' />
                            </span>
                          ) : null}{' '}
                        </>
                      ) : null}
                    </Col>
                  </Row>
                  {!batchApprovalLoading && batchApprovalError ? (
                    <Error message={messageList.error} dismissible={true} />
                  ) : null}
                  <Row className='mt-3'>
                    <Card className='shadow col-lg-10 col-md-10 col-sm-12 col-xs-12 pb-5 mb-5'>
                      <CardHeader className='text-left no-border'>
                       {batchSummary.is_external?<span className='h4 d-block'>Invoice Details</span>:<span className='h4 d-block'>Upstream Batches</span>} 
                      </CardHeader>
                      <CardBody>
                        {currentBatches.length ? (
                          <>
                            <Table striped responsive>
                              <thead>
                                <tr>
                                  <th>Upstream Batch</th>
                                  <th>Type</th>
                                  <th>Created At</th>
                                  <th>Approval Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentBatches.map((data, index) => (
                                  <tr
                                    key={index}
                                    className={
                                      data.department && data.department.id
                                        ? 'showpointer'
                                        : ''
                                    }
                                    onClick={() => {
                                      if (data.department && data.department.id)
                                        this.handleClick(
                                          data.department.id,
                                          data
                                        );
                                    }}
                                  >
                                    <td>{data.batch_number}</td>
                                    <td>{data.stage_name}</td>
                                    <td>
                                      <Moment format='DD/MM/YYYY'>
                                        {data.creation_date}
                                      </Moment>
                                    </td>
                                    <td className='text-capitalize'>
                                      {data.approval_status.toLowerCase() ===
                                      'pending' ? (
                                        <>
                                          <span className='text-danger pr-2'>
                                            &bull;
                                          </span>
                                          {data.approval_status}
                                        </>
                                      ) : (
                                        <>
                                          <span className='text-success pr-2'>
                                            &bull;
                                          </span>
                                          {data.approval_status}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <div className='mt-4'>
                              {this.state.pageCount > 1 ? (
                                <Paginate
                                  currentPage={parseInt(this.state.currentPage)}
                                  paginate={this.paginate}
                                  targetArrayLength={
                                    this.state.batchSummary.upstream_batches
                                      .length
                                  }
                                />
                              ) : null}
                            </div>
                          </>
                        ) : !currentBatches.length && batchSummary.is_external? (
                          <Table striped responsive>
                            <thead>
                              <tr>
                                <th>INVOICE NO.</th>
                                <th>VENDOR NAME</th>
                                <th>CREATED AT</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                        <td>{batchSummary.metadata.invoice_num}</td>
                        <td>{batchSummary.metadata.vendor_name}</td>
                        <td><Moment format='DD/MM/YYYY'>{batchSummary.creation_date}</Moment></td>
                              </tr>
                            </tbody>
                          </Table>
                        ):(
                          <h4 className='subheader text-left h1'>
                            NO UPSTREAM BATCHES FOUND{' '}
                          </h4>
                        )}
                      </CardBody>
                    </Card>

                    <div className='col-lg-8 col-md-8 col-sm-12 col-xs-12 p-3 bg-white border-radius'>
                      <CardHeader className='text-left no-border pt-0'>
                        <span className='h4 d-block'>Supporting Documents</span>
                      </CardHeader>
                      <CardBody>
                        {externalActivities.length +
                        internalActivities.length ? (
                          <Table striped responsive>
                            <thead className='text-left'>
                              <tr>
                                <th scope='col'>Name</th>
                                <th scope='col'>Description</th>
                                <th scope='col'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {internalActivities.map((activity, index) => (
                                <tr key={index} className='text-left'>
                                  <td className='font-weight-bold'>
                                    {activity.name}
                                  </td>
                                  <td>{activity.description}</td>
                                  <td
                                    className='text-primary showpointer'
                                    onClick={() => {
                                      this.toggleReportModal(
                                        'internal',
                                        activity.name,
                                        activity.documents[0].metadata
                                      );
                                    }}
                                  >
                                    View
                                  </td>
                                </tr>
                              ))}
                              {externalActivities.map((activity, index) => (
                                <tr key={index} className='text-left'>
                                  <td className='font-weight-bold'>
                                    {activity.name}
                                  </td>
                                  <td>{activity.description}</td>
                                  <td
                                    className='text-primary showpointer'
                                    onClick={() => {
                                      this.toggleReportModal(
                                        'external',
                                        activity.name,
                                        activity.documents
                                      );
                                    }}
                                  >
                                    View
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        ) : (
                          <h4 className='subheader text-left h1'>
                            NO DOCUMENTS FOUND{' '}
                          </h4>
                        )}
                        {permissionsManager(batchSummary.role.toLowerCase()) >
                        1 ? (
                          <Button
                            className='btn-border-style-outline mt-4 float-left'
                            color='primary'
                            onClick={() => {
                              this.toggleReportModal('new');
                            }}
                          >
                            Add Documents
                          </Button>
                        ) : null}
                      </CardBody>
                    </div>
                  </Row>
                </>
              ) : null
            ) : null}
          </div>
        </Container>

        <Modal
          size={this.state.reportType === 'internal' ? 'md' : 'lg'}
          isOpen={this.state.toggleDocModal}
          toggle={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal} className='h1'>
            <span className='h3'>{this.state.reportName}</span>
          </ModalHeader>
          <ModalBody>
            {this.state.reportType === 'internal' ? (
              <Table striped responsive>
                <tbody>
                  {Object.keys(this.state.internalReport).map(key => {
                    return (
                      <tr key={key}>
                        <td className='font-weight-bold text-capitalize p-1'>
                          {key.split('_').join(' ')}
                        </td>
                        <td className='p-1'>
                          {this.state.internalReport[key]}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : this.state.reportType === 'external' ? (
              <CardBody>
                <h4> List of Documents</h4>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.documentList.map((doc, docIndex) => {
                      return (
                        <tr key={docIndex}>
                          <td className='font-weight-bold'>{doc.name}</td>
                          <td>{doc.description}</td>
                          <td>
                            {doc.url ? (
                              <span
                                className='text-primary showpointer font-weight-bold'
                                onClick={() =>
                                  downloadDocument(doc.url, doc.name)
                                }
                              >
                                View
                              </span>
                            ) : // <a
                            //   href={doc.url}
                            //   target='_blank'
                            //   rel='noopener noreferrer'
                            // >
                            //   View
                            // </a>
                            null
                            // <span className="text-primary showpointer" onClick={(e) => this.toggleModal(doc.name, subDocuments)}> View</span>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {this.state.reportType === 'new' ? (
                  <>
                    <FormGroup className='mt-4'>
                      <Label for='actStDate'>Add Multiple Documents</Label>{' '}
                    </FormGroup>
                    {this.state.addDocumentRenderer.map((group, index) => (
                      <UploadDocModule
                        trackDocumentUpload={this.trackDocumentUpload}
                        index={index}
                        key={index}
                        inputName={group}
                        // allApprovers={allApprovers}
                        removeDocAddWindow={this.removeDocAddWindow}
                        fileDetails={this.state.fileUploadList[index]}
                      />
                    ))}
                    <FormGroup className='mt-2'>
                      <Button
                        className='btn-border-style-outline'
                        color='primary'
                        onClick={e => this.handleAddDocument(e)}
                      >
                        <i className='ni ni-fat-add'></i>
                        {this.state.addDocumentRenderer.length
                          ? 'Click to add more'
                          : 'Click to Add'}
                      </Button>
                    </FormGroup>
                  </>
                ) : null}
              </CardBody>
            ) : (
              <>
                <Form>
                  <FormGroup>
                    <Input
                      className='border theme-border-color rounded p-2'
                      autoComplete='off'
                      placeholder='Enter Name'
                      onChange={event =>
                        this.inputChangeHandler(event, 'activityName')
                      }
                    />
                    {this.state.activityNameTouched &&
                    !this.state.activityNameOkay ? (
                      <span className='text-danger'>
                        <small>
                          {this.validators['activityName'].errorMessage}
                        </small>
                      </span>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      className='border theme-border-color rounded p-2'
                      autoComplete='off'
                      placeholder='Enter Description'
                      type='textarea'
                      onChange={event =>
                        this.inputChangeHandler(event, 'activityDes')
                      }
                    />
                    {/* {this.state.activityDesTouched &&
                          !this.state.activityDesOkay ? (
                            <span className='text-danger'>
                              <small>
                                {this.validators['activityDes'].errorMessage}
                              </small>
                            </span>
                          ) : null} */}
                  </FormGroup>
                  <Label for='actStDate'>Add Multiple Documents</Label>{' '}
                  {this.state.addDocumentRenderer.map((group, index) => (
                    <UploadDocModule
                      trackDocumentUpload={this.trackDocumentUpload}
                      index={index}
                      key={index}
                      inputName={group}
                      // allApprovers={allApprovers}
                      removeDocAddWindow={this.removeDocAddWindow}
                      fileDetails={this.state.fileUploadList[index]}
                    />
                  ))}
                  <FormGroup className='mt-4'>
                    <Button
                      className='btn-border-style-outline'
                      color='secondary'
                      onClick={e => this.handleAddDocument(e)}
                    >
                      <i className='ni ni-fat-add'></i>
                      {this.state.addDocumentRenderer.length
                        ? 'Click to add more'
                        : 'Click to Add'}
                    </Button>
                  </FormGroup>
                </Form>
              </>
            )}
          </ModalBody>
          {this.state.reportType ===
          'new' /*|| this.state.reportType === 'external'*/ ? (
            <ModalFooter>
              {!this.state.isWorking ? (
                <div>
                  {this.state.reportType === 'new' ? (
                    <Button
                      className='btn-border-style-outline'
                      color='primary'
                      type='button'
                      disabled={!this.state.enableSubmit}
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      className='btn-border-style-outline'
                      color='primary'
                      type='button'
                      disabled={!this.state.enableUpdate}
                      // onClick={() => {
                      //   updateDocuments();
                      // }}
                    >
                      Update
                    </Button>
                  )}
                  <Button
                    className='btn-border-style-outline'
                    color='secondary'
                    data-dismiss='modal'
                    type='button'
                    onClick={() => {
                      this.toggleModal();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div className='w-100'>
                  <Progress animated bar value='100'>
                    <strong>
                      Please Wait while the documents are uploading...
                    </strong>
                  </Progress>
                </div>
              )}
            </ModalFooter>
          ) : null}
        </Modal>
        <Modal
          isOpen={this.state.warningModalOpen}
          toggle={this.toggleWarningModal}
        >
          <ModalHeader toggle={this.toggleWarningModal}>
            Confirm{' '}
            {batchSummary.stage && batchSummary.stage.name
              ? batchSummary.stage.name
              : 'Batch'}
          </ModalHeader>
          <ModalBody>
            <div className='mt-2'>
              <span className='text-warning font-weight-bold'>
                This will write the data permanently to the blockchain.
              </span>
            </div>
            <div className='mt-2'>
              Are you sure you want to approve this{' '}
              {batchSummary.stage && batchSummary.stage.name
                ? batchSummary.stage.name
                : 'Batch'}
              ?{' '}
            </div>
          </ModalBody>
          <ModalFooter className='bg-welspun-secondary'>
            <Button color='warning' onClick={this.approveBatch}>
              Approve
            </Button>{' '}
            <Button color='white' onClick={this.toggleWarningModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  batchSummary: state.departments.batchById,
  activityMsg: state.activities.msg,
  activitySuccess: state.activities.success,
  activityError: state.activities.errors,
  activityLoading: state.activities.loading,
  batchLoading: state.departments.loading,
  batchSuccess: state.departments.success,
  batchError: state.departments.errors,
  batchMsg: state.departments.msg,
  batchApprovalLoading: state.departments.batchApprovalLoading,
  batchApprovalSuccess: state.departments.batchApprovalSuccess,
  batchApprovalError: state.departments.batchApprovalErrors,
  batchApprovalMsg: state.departments.batchApprovalMsg
});
const mapDispatchToProps = dispatch => ({
  onGetBatchSummary: (batchId, deptId) =>
    dispatch(actions.getBatchById(batchId, deptId)),
  onAddNewActivity: (batchId, data) =>
    dispatch(actions.addNewActivity(batchId, data)),
  onApproveBatch: (batchNumber, deptId, batchId) =>
    dispatch(actions.approveBatchbyNumber(batchNumber, deptId, batchId))
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchSummary);
