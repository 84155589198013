import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

export default function Error({message, dismissible=false,colSize=null, offset=null, }) {

    const [closeAlert, setCloseAlert] = useState(false)
  return (
      <>
        {!closeAlert?
        <Row>
        <Col className={`${colSize?colSize:'col-4'} ${offset?offset:'offset-3'}`}>
          <div
            className={`alert alert-warning ${
              dismissible ? 'alert-dismissible fade show' : ''
            }`}
            role='alert'
          ><strong>{message}</strong>
            {dismissible?<button
              type='button'
              className='close'
              data-dismiss='alert'
              aria-label='Close'
              onClick={()=>setCloseAlert(true)}
            >
              <span aria-hidden='true' className="text-white">&times;</span>
            </button>:null}
          </div>
        </Col>
      </Row>:null
    }
      </>
    
  );
}
