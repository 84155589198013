import React from 'react';
// react plugin used to create datetimepicker
import ReactDatetime from 'react-datetime';
import './DateRangePicker.css';
// reactstrap components
import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row
} from 'reactstrap';
import moment from 'moment';

class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate
    };
  }
  render() {
    return (
      <>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <InputGroup className='input-group-alternative'>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>
                    <i className='ni ni-calendar-grid-58' />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: this.props.startDate
                      ? moment(this.props.startDate._d).format('DD/MM/YYYY')
                      : 'Start Date'
                  }}
                  value={this.props.startDate}
                  timeFormat={false}
                  dateFormat={'DD/MM/YYYY'}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + '' === currentDate._d + ''
                    ) {
                      classes += ' start-date';
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + '') <
                        new Date(currentDate._d + '') &&
                      new Date(this.state.endDate._d + '') >
                        new Date(currentDate._d + '')
                    ) {
                      classes += ' middle-date';
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + '' === currentDate._d + ''
                    ) {
                      classes += ' end-date';
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={e => this.setState({ startDate: e },() => {
                    this.props.runFilters({
                      startDate: this.state.startDate,
                      endDate: this.state.endDate
                    });
                  })}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <InputGroup className='input-group-alternative'>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>
                    <i className='ni ni-calendar-grid-58' />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: this.props.endDate
                      ? moment(this.props.endDate._d).format('DD/MM/YYYY')
                      : 'End Date'
                  }}
                  timeFormat={false}
                  dateFormat={'DD/MM/YYYY'}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + '' === currentDate._d + ''
                    ) {
                      classes += ' start-date';
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + '') <
                        new Date(currentDate._d + '') &&
                      new Date(this.state.endDate._d + '') >
                        new Date(currentDate._d + '')
                    ) {
                      classes += ' middle-date';
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + '' === currentDate._d + ''
                    ) {
                      classes += ' end-date';
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}

                  value={this.props.endDate}
                  onChange={e =>
                    this.setState({ endDate: e }, () => {
                      this.props.runFilters({
                        startDate: this.state.startDate,
                        endDate: this.state.endDate
                      });
                    })
                  }
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default Datepicker;
