import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './Breadcrumb.css'
const BreadcrumbLayout = props => {
  const handleBreadCrumbClick = function(item) {
    props.goBack(item);
  };
  return (
    <Breadcrumb>
      {props.items.map((item, index) => {
        return (
          <BreadcrumbItem key={index} >
            {
              <span
                onClick={() => {
                  if(props.items.length - 1 !== index) handleBreadCrumbClick(item);
                }}
                className={props.items.length - 1 === index ? 'font-weight-bold' : 'showpointer'}
              >
                {item.batchNumber ? item.batchNumber : item.deptName}
              </span>
            }
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadcrumbLayout;
