import * as actionTypes from './action-types';
import { api } from '../../axios-instance';
import {
  headers,
  getFromLocalStorage,
  backendRoutes
} from '../../shared/utility';
import * as actions from './index';

export const initStart = _ => ({
  type: actionTypes.INIT_START
});

export const initSuccess = departments => ({
  type: actionTypes.INIT_SUCCESS,
  departments
});

export const initFail = (error, msg) => ({
  type: actionTypes.INIT_FAIL,
  error,
  msg
});

export const init = () => {
  let loggedInUserId = getFromLocalStorage('sub');
  return dispatch => {
    dispatch(initStart());
    api
      .get(backendRoutes.getAllDepartmentsByUser(loggedInUserId), headers())
      .then(response => {
        dispatch(breadCrumbSuccess('Departments', null, 0, 0, undefined));
        dispatch(initSuccess(response.data.result.payload));
      })
      .catch(error => {
        dispatch(initFail(error, error.message));
      });
  };
};

export const getDepartmentByIdStart = _ => ({
  type: actionTypes.GET_DEPARTMENT_BY_ID_START
});

export const getDepartmentByIdSuccess = departmentById => ({
  type: actionTypes.GET_DEPARTMENT_BY_ID_SUCCESS,
  departmentById
});

export const getDepartmentByIdFail = (error, msg) => ({
  type: actionTypes.GET_DEPARTMENT_BY_ID_FAIL,
  error,
  msg
});

export const getDepartmentById = deptId => {
  return dispatch => {
    dispatch(getDepartmentByIdStart());
    api
      .get(backendRoutes.getDepartmentById(deptId), headers())
      .then(response => {
        let { payload } = response.data.result;
        dispatch(getDepartmentByIdSuccess(payload));
        dispatch(
          breadCrumbSuccess(payload.name, null, 0, null, payload, payload.id)
        );
      })
      .catch(error => {
        dispatch(getDepartmentByIdFail(error, error.message));
      });
  };
};

export const exportBatchListFileStart = _ => ({
    type: actionTypes.EXPORT_BATCH_LIST_FILE_START
  });

  export const exportBatchListFileSuccess = report => ({
    type: actionTypes.EXPORT_BATCH_LIST_FILE_SUCCESS,
    report
  });

  export const exportBatchListFileFail = (error, msg) => ({
    type: actionTypes.EXPORT_BATCH_LIST_FILE_FAIL,
    error,
    msg
  });

  export const exportBatchListFile = stageName => {
    return dispatch => {
      dispatch(exportBatchListFileStart());
      api
        .get(backendRoutes.exportBatchByStageName(stageName), headers())
        .then(response => {
          console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${stageName}_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.csv`);
                console.log(link)
                link.click();
                link.remove()

          dispatch(exportBatchListFileSuccess(true));
        })
        .catch(error => {
          dispatch(exportBatchListFileFail(error, error.message));
        });
    };
  };

export const getBatchByIdStart = _ => ({
  type: actionTypes.GET_BATCH_BY_ID_START
});

export const getBatchByIdSuccess = batchById => ({
  type: actionTypes.GET_BATCH_BY_ID_SUCCESS,
  batchById
});

export const getBatchByIdFail = (error, msg) => ({
  type: actionTypes.GET_BATCH_BY_ID_FAIL,
  error,
  msg
});

export const getBatchById = (batchId, deptId) => {
  return dispatch => {
    dispatch(getBatchByIdStart());
    api
      .get(backendRoutes.getBatchByIdforUser(batchId, deptId), headers())
      .then(response => {
        let { payload } = response.data.result;
        dispatch(getBatchByIdSuccess(payload));
        dispatch(
          breadCrumbSuccess(
            payload.name ? payload.name : payload.department.name,
            'Batch',
            payload.batch_number,
            payload.id,
            payload,
            deptId
          )
        );
      })
      .catch(error => {
        dispatch(getBatchByIdFail(error, error.message));
      });
  };
};

export const approveBatchbyNumberStart = _ => ({
  type: actionTypes.APPROVE_BATCH_BY_NUMBER_START
});

export const approveBatchbyNumberSuccess = batchApprovalStatus => ({
  type: actionTypes.APPROVE_BATCH_BY_NUMBER_SUCCESS,
  batchApprovalStatus
});

export const approveBatchbyNumberFail = (error, msg) => ({
  type: actionTypes.APPROVE_BATCH_BY_NUMBER_FAIL,
  error,
  msg
});

export const approveBatchbyNumber = (batchNumber, deptId, batchId) => {
  return dispatch => {
    dispatch(approveBatchbyNumberStart());
    api
      .post(
        backendRoutes.approveBatchByNumber(batchNumber.replace(/\//g, '%2F')),
        { data: {} },
        headers()
      )
      .then(response => {
        dispatch(getBatchById(batchId, deptId));
        dispatch(approveBatchbyNumberSuccess(response.data.result.payload));
      })
      .catch(error => {
        dispatch(approveBatchbyNumberFail(error, error.message));
      });
  };
};

export const breadCrumbSuccess = (
  deptName,
  batchName,
  batchNumber,
  batchId,
  data,
  deptId = ''
) => {
  return {
    type: actionTypes.BREADCRUMB,
    deptName,
    batchName,
    batchNumber,
    batchId,
    data,
    deptId
  };
};

export const breadCrumb = (
  deptName,
  batchName,
  batchId,
  batchNumber,
  data = undefined,
  deptId = undefined
) => {
  return dispatch => {
    if (batchId) dispatch(actions.getBatchById(batchId, deptId));
    dispatch(
      breadCrumbSuccess(deptName, batchName, batchNumber, batchId, data, deptId)
    );
  };
};
