import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as serviceWorker from "./serviceWorker";
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { Router } from "react-router-dom";

import thunk from "redux-thunk";

import App from "./App";
import "./index.css";

import authReducer from "./store/reducers/auth";
import departmentReducer from './store/reducers/departments'
import { reducer as traceabilityReducer } from './store/reducers/drilldown';
import { reducer as soTraceabilityReducer } from './store/reducers/traceability';
import { reducer as activityReducer } from './store/reducers/batches';
import { reducer as documentReducer } from './store/reducers/documents';
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

const history = createBrowserHistory();
require('dotenv').config()

const trackingId = process.env.REACT_APP_GA_TAG; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname);

// // // Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const rootReducer = combineReducers({
  auth: authReducer,
  departments: departmentReducer,
  traceability: traceabilityReducer,
  activities: activityReducer,
  soTraceability: soTraceabilityReducer,
  certDocuments: documentReducer
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
