import * as actionTypes from "./action-types";
import { api } from '../../axios-instance'
import jwt_decode from 'jwt-decode'
import { saveInLocalStorage, removeFromLocalStorage, getFromLocalStorage, backendRoutes } from '../../shared/utility';


export const signInStart = _ => ({
  type: actionTypes.SIGNIN_START
});

export const signInSuccess = (email, token, msg, expiry, sub) => ({
  type: actionTypes.SIGNIN_SUCCESS,
  email,
  token,
  expiry,
  msg,
  sub,
  authRedirectPath: "/",
  //role: role
});


export const signInFail = (error, email) => ({
  type: actionTypes.SIGNIN_FAIL,
  error: error,
  email: email
});

export const signOut = _ => {
  removeFromLocalStorage("token");
  removeFromLocalStorage("email");
  removeFromLocalStorage("expiry");
  removeFromLocalStorage("role");
  removeFromLocalStorage("sub");
  removeFromLocalStorage("name");
  removeFromLocalStorage('user_type')

  return {
    type: actionTypes.SIGNOUT
  };
};

export const setAuthRedirectPath = path => ({
  type: actionTypes.SET_AUTH_REDIRECT_PATH,
  path: path
});

export const checkAuthTimeout = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch(signOut());
    }, 4 * 60 * 60 * 1000);
  };
};
export const resetMessages = () => ({
  type: actionTypes.RESET_MESSAGES
});
export const resetAlerts = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch(resetMessages());
    }, 5000);
  };
}

export const signIn = (username, password) => {

  const formData = new FormData()
  formData.append('email', username)
  formData.append('password', password)
  return dispatch => {
    dispatch(signInStart())
    api.post(backendRoutes.login, formData)
      .then(response => {
        let { success, result } = response.data;
        let { errors, msg, payload } = result;
        let { token, user } = payload
        if (success === true && !errors) {
          saveInLocalStorage('email', user.email)
          saveInLocalStorage('token', token)
          var decoded = jwt_decode(token);
          saveInLocalStorage('expiry', decoded.exp)
          saveInLocalStorage('sub', decoded.sub.user_id)
          saveInLocalStorage('name', decoded.sub.name)
          saveInLocalStorage('user_type', decoded.sub.user_type)

          dispatch(signInSuccess(
            user.email,
            token,
            msg,
            decoded.exp,
          ))
          dispatch(checkAuthTimeout());
          // dispatch(resetAlerts());
        } else {
          dispatch(signInFail(
            'Invalid Credentials',
            username
          ))
          dispatch(resetAlerts())
        }
        // }
      })
      .catch(error => {
        dispatch(signInFail(`Invalid Credentials`, username))
        dispatch(resetAlerts())
      })
  }
};

export const signUpStart = _ => ({
  type: actionTypes.SIGNUP_START
});

export const signUpSuccess = data => ({
  type: actionTypes.SIGNUP_SUCCESS,
  data: data,
  authRedirectPath: "/auth/login"
});

export const signUpFail = (msg, errors) => ({
  type: actionTypes.SIGNUP_FAIL,
  msg: msg,
  errors: errors
});

export const signUp = (name, email, password) => {
  return (dispatch => {
    dispatch(signUpStart())
    const userInfo = {
      email: email,
      name: name,
      password: password,
      organization_id: "1"
    }

    api.post(backendRoutes.register, userInfo)
      .then(response => {
        if (response.data.success === true)
          dispatch(signUpSuccess(response.data))
        else
          dispatch(signUpFail(response.data.result.msg, response.data.result.errors))
      }).catch(error => {
        console.log(error)
        dispatch(signUpFail(error))
      })
  })
}

export const forgotPasswordStart = _ => ({
  type: actionTypes.FORGOT_PASSWORD_START
});

export const forgotPasswordSuccess = data => ({
  type: actionTypes.FORGOT_PASSWORD_SUCCESS,
  data: data
});

export const forgotPasswordFail = (error, msg) => ({
  type: actionTypes.FORGOT_PASSWORD_FAIL,
  msg,
  error
});

export const forgotPassword = (email) => {
  return (dispatch => {
    dispatch(forgotPasswordStart())
    const data = {email}
    api.post(backendRoutes.forgotPassword, data)
      .then(response => {
          dispatch(forgotPasswordSuccess(response.data.result.payload))
          dispatch(resetMessages())
      }).catch(error => {
        dispatch(forgotPasswordFail(error, error.message))
        dispatch(resetMessages())
      })
  })
}

export const checkResetPasswordTokenStart = _ => ({
  type: actionTypes.CHECK_RESET_PASSWORD_TOKEN_START
});

export const checkResetPasswordTokenSuccess = data => ({
  type: actionTypes.CHECK_RESET_PASSWORD_TOKEN_SUCCESS,
  data: data
});

export const checkResetPasswordTokenFail = (error,msg) => ({
  type: actionTypes.CHECK_RESET_PASSWORD_TOKEN_FAIL,
  msg,
  error
});

export const checkResetPasswordToken = (token) => {
  return (dispatch => {
    dispatch(checkResetPasswordTokenStart())
    const data = {token}
    setTimeout(()=>{
    },2000)
    api.post(backendRoutes.checkToken, data)
      .then(response => {
          dispatch(checkResetPasswordTokenSuccess(response.data.result.payload))
          dispatch(resetMessages())
      }).catch(error => {
        dispatch(checkResetPasswordTokenFail(error, error.message))
        dispatch(resetMessages())
      })
  })
}

export const resetPasswordStart = _ => ({
  type: actionTypes.RESET_PASSWORD_START
});

export const resetPasswordSuccess = data => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  data: data
});

export const resetPasswordFail = (error,msg) => ({
  type: actionTypes.RESET_PASSWORD_FAIL,
  msg,
  error
});

export const resetPassword = (password, token) => {
  return (dispatch => {
    dispatch(resetPasswordStart())
    // const data = {password, token}
    setTimeout(()=>{
      dispatch(resetPasswordSuccess('response.data.result.payload'))
      dispatch(resetMessages())
    },3000)
    // api.post('/api/resetpassword', data)
    //   .then(response => {
    //       dispatch(resetPasswordSuccess(response.data.result.payload))
    //   }).catch(error => {
    //     dispatch(resetPasswordFail(error, error.message))
    //   })
  })
}

export const checkAuthState = _ => dispatch => {
  const email = getFromLocalStorage("email");
  const token = getFromLocalStorage("token");
  const expiry = getFromLocalStorage("expiry");
  const role = getFromLocalStorage("role");
  const sub = getFromLocalStorage("sub");
  if (Math.floor(Date.now() / 1000) < expiry) {
    dispatch(signInSuccess(email, token, "Login sucessful ", expiry, role, sub));
  } else {
    dispatch(signOut());
  }
};

