import React from 'react'
import Pagination from "react-js-pagination";
export default function Paginate(props) {
    return (
        <Pagination 
        prevPageText={'Prev'}
        nextPageText={'Next'}
        activePage={parseInt(props.currentPage)}
        itemsCountPerPage={10}
        totalItemsCount={props.targetArrayLength}
        pageRangeDisplayed={6}
        onChange={props.paginate}
        itemClass='page-me'
        itemClassPrev='d-block'
        itemClassNext='d-block'
        itemClassFirst='d-block'
        itemClassLast='d-block'

        activeLinkClass="active"

       />

    //    <ReactPaginate
                                 
                                 
    //                               pageClassName={'page-me'}
    //                               previousClassName={'setmargin'}
    //                               pageCount={this.state.pageCount}
    //                               marginPagesDisplayed={2}
    //                               pageRangeDisplayed={3}
    //                               onPageChange={this.paginate}
    //                               containerClassName={'pagination'}
    //                               subContainerClassName={'pages pagination'}
    //                               activeClassName={'active'}
    //                             />

    )
}
