import { api } from "../axios-instance";
import { headers, backendRoutes } from "./utility";


export const downloadDocument = (fileUrl, fileName) => {
    api
      .get(
        `${backendRoutes.downloadDocuments(fileUrl)}`, { ...headers(), responseType: 'blob' }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data],{type:response.data.type}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('type', response.data.type) 
        link.setAttribute('target','_blank')
        // link.setAttribute('download', `${fileName}.${response.data.type.split('/')[1]}`);
        link.click();
        // link.remove();
      })
      .catch(error => {
        console.log(error)
      });
}