/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import { simpleErrorMsg, titleCaseConverter } from '../../shared/utility';
import Validators from '../../shared/Validations';
import * as actions from '../../store/actions';

function LoginDarkTheme() {
  const dispatch = useDispatch();
  const [emailProperties, setEmailProperties] = useState({
    email: '',
    emailTouched: false,
    emailOkay: false,
    emailClicked: false
  });
  const [passwordProperties, setPasswordProperties] = useState({
    password: '',
    passwordClicked: false,
    passwordTouched: false,
    passwordOkay: false
  });
  const functionTypeHandler = {
    emailProperties,
    setEmailProperties,
    passwordProperties,
    setPasswordProperties
  };

  const [enableSubmit, setEnableSubmit] = useState(false);
  const validators = {
    email: {
      validators: value => Validators.email(value),
      errorMessage: 'Invalid Email'
    },
    password: {
      validators: value => Validators.fieldRequired(value, 0),
      errorMessage: 'Password cannot be blank'
    }
  };

  const loading = useSelector(state => state.auth.loading);
  const error = useSelector(state => state.auth.error);
  const errorMessage = useSelector(state => state.auth.msg);
  const isAuthenticated = useSelector(state => state.auth.email !== null);
  const authRedirectPath = useSelector(state => state.auth.authRedirectPath);

  const submitHandler = event => {
    event.preventDefault();
    inputBlurHandler(emailProperties.email, 'email');
    inputBlurHandler(passwordProperties.password, 'password');
    if (handleEnableSubmit())
      dispatch(
        actions.signIn(emailProperties.email, passwordProperties.password)
      );
  };

  const inputFocusHandler = controlName => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}Clicked`]: true
    });
  };

  const inputChangeHandler = (value, controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}`]: value,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: validators[controlName].validators(value)
    });
    setEnableSubmit(handleEnableSubmit());
  };

  const inputBlurHandler = (value, controlName) => {
    functionTypeHandler[`set${titleCaseConverter(controlName)}Properties`]({
      ...functionTypeHandler[`${controlName}Properties`],
      [`${controlName}Clicked`]: false,
      [`${controlName}`]: value,
      [`${controlName}Touched`]: true,
      [`${controlName}Okay`]: validators[controlName].validators(value)
    });
  };

  const handleEnableSubmit = () => {
    return (
      emailProperties.emailTouched &&
      emailProperties.emailOkay &&
      passwordProperties.passwordTouched &&
      passwordProperties.passwordOkay
    );
  };

  useEffect(() => {
    dispatch(actions.checkAuthState());
  }, [dispatch]);

  let authRedirect = null;

  if (isAuthenticated && authRedirectPath !== '/auth/login') {
    authRedirect = <Redirect to={authRedirectPath} />;
  }
  return (
    <>
      <div className='m-0 b-0'>
        {authRedirect}
        <Card className='bg-transparent border-0'>
          <CardHeader className='m-0 bg-transparent border-0'>
            <div className='text-center text-muted my-4'>
              {/* <img src={Logo} alt="logo" width="100%" /> */}
            </div>
          </CardHeader>
          <CardBody>
            <Form
              role='form'
              onKeyPress={e =>
                e.key === 'Enter' && enableSubmit ? submitHandler(e) : null
              }
            >
              <FormGroup className='mb-3'>
                <InputGroup className='input-group-alternative border-style'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <img
                        alt='...'
                        src={require('../../assets/img/icons/mail.svg')}
                        width='80%'
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name='email'
                    autoComplete='off'
                    placeholder='Email'
                    type='email'
                    onFocus={() => inputFocusHandler('email')}
                    onChange={e => {
                      inputChangeHandler(e.target.value, 'email');
                    }}
                    onBlur={event => {
                      inputBlurHandler(event.target.value, 'email');
                    }}
                  />
                </InputGroup>
                {emailProperties.emailTouched &&
                !emailProperties.emailOkay &&
                !emailProperties.emailClicked ? (
                  <span className='text-center'>
                    {simpleErrorMsg(validators['email'].errorMessage)}
                  </span>
                ) : null}
              </FormGroup>
              <FormGroup>
                <InputGroup className='input-group-alternative border-style'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <img
                        alt='...'
                        src={require('../../assets/img/icons/lock.svg')}
                        width='80%'
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name='password'
                    autoComplete='off'
                    placeholder='Password'
                    type='password'
                    onFocus={() => inputFocusHandler('password')}
                    onChange={e => {
                      inputChangeHandler(e.target.value, 'password');
                    }}
                    onBlur={event => {
                      inputBlurHandler(event.target.value, 'password');
                    }}
                  />
                </InputGroup>
                {passwordProperties.passwordTouched &&
                !passwordProperties.passwordOkay &&
                !passwordProperties.passwordClicked ? (
                  <span className='text-center'>
                    {simpleErrorMsg(validators['password'].errorMessage)}
                  </span>
                ) : null}
              </FormGroup>
              <div className='text-center'>
                <Button
                  className='my-4 btn-block btn-border-style text-uppercase'
                  color='primary'
                  type='button'
                  onClick={submitHandler}
                  disabled={loading}
                >
                  <span className='h3 text-white'>Log in</span>
                </Button>
              </div>
            </Form>
            {error ? (
              <Alert color='warning'>
                <strong>Warning!</strong> {errorMessage}
              </Alert>
            ) : null}
          </CardBody>
        </Card>
        <Row className='mt-3'>
          <Col xs='6'></Col>
        </Row>
      </div>
    </>
  );
}

export default LoginDarkTheme;
