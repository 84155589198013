import React, { useState, useEffect } from 'react';
import Paginate from '../../shared/Pagination/Paginate';
import { Row, Table } from 'reactstrap';
export default function SoTable(props) {
  const [selectedSO, setSelectedSO] = useState('');
  let table_headers = [
    'CUSTOMER PO NO.',
    'COTTON TYPE',
    'SO NO.',
    'SO LINE ITEM',
    'CUSTOMER NAME',
    'SO QUANTITY',
    'DESCRIPTION',
    'SHADE',
    'SIZE'
    // 'ACTION'
  ];
  const [tableData, setTableData] = useState([]);
  const postsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [indexOfLastPost, setIndexOfLastPost] = useState(
    currentPage * postsPerPage
  );
  const [indexOfFirstPost, setIndexOfFirstPost] = useState(
    indexOfLastPost - postsPerPage
  );
  const [currentSOList, setCurrentSOList] = useState(
    tableData.slice(indexOfFirstPost, indexOfLastPost)
  );

  useEffect(() => {
    setTableData(props.soList);
    let iOLastPost = currentPage * postsPerPage;
    let iOFirstPost = iOLastPost - postsPerPage;
    let curSoList = props.soList.slice(iOFirstPost, iOLastPost);
    setIndexOfLastPost(iOLastPost);
    setIndexOfFirstPost(iOFirstPost);
    setCurrentSOList(curSoList);
    return setSelectedSO('');
  }, [props.soList, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.soList]);

  const pageCount = Math.ceil(tableData.length / postsPerPage);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber.selected ? pageNumber.selected + 1 : pageNumber);
  };

  // const handleQrClicked = (e, has_qr_code, data) => {
  //   e.stopPropagation();
  //   setSelectedSO(data.name);
  //   props.qrAction(has_qr_code, data);
  // };

  const handleSOLineItemClicked = soLineItem => {
    props.selectedSo(soLineItem.name, soLineItem.has_qr_code);
    if(soLineItem.has_qr_code)
    setSelectedSO(soLineItem);
  };
  return (
    <Row className='card shadow'>
      <Table striped responsive hover>
        <thead>
          <tr>
            {table_headers.map((head, index) => (
              <th key={index} style={{ width: '10%' }}>
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentSOList.length ? (
            currentSOList.map((data, index) => (
              <tr
                key={index}
                className={`showpointer ${
                  data.name === selectedSO.name ? 'active font-weight-bold' : ''
                }`}
                onClick={() => {
                  handleSOLineItemClicked(data);
                }}
              >
                <td className='td-setter'>{data.customer_po_number}</td>
                <td className='td-setter'>{data.cotton_type}</td>
                <td className='td-setter'>{data.sales_order_number}</td>
                <td className='td-setter'>{data.name}</td>
                <td className='td-setter'>{data.customer_name}</td>
                <td className='td-setter'>
                  {data.base_quantity} {data.base_unit}
                </td>
                <td className='reset-td'>{data.description}</td>
                <td className='td-setter'>{data.shade}</td>
                <td className='td-setter'>{data.size}</td>
                {/* <td
                  className='px-1'
                  onClick={e => handleQrClicked(e, data.has_qr_code, data)}
                >
                  <span
                    className={`text-primary showpointer ${
                      data.has_qr_code ? 'text-dark' : 'text-primary'
                    } font-weight-bold border-0`}
                  >
                    {data.has_qr_code ? 'View QR' : 'Generate QR'}
                  </span>
                </td> */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan='9'>
                <strong>Not Found</strong>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {pageCount > 1 ? (
        <Paginate
          currentPage={parseInt(currentPage)}
          paginate={paginate}
          targetArrayLength={tableData.length}
        />
      ) : null}
      {selectedSO?
      <div className='section-details bg-welspun-theme-primary d-none d-sm-none d-md-block d-lg-block'>
        <button type="button" className="close mr-2 mb-2" aria-label="Close" onClick={()=>{setSelectedSO(''); props.selectedSo('',true)}}>
  <span className="text-white" aria-hidden="true">&times;</span>
</button>
        <Table striped responsive size="sm">
          <tbody>
            <tr>
              <td>PO NO.</td>
              <td>{selectedSO.customer_po_number}</td>
            </tr>
            <tr>
              <td>Cotton Type</td>
              <td>{selectedSO.cotton_type}</td>
            </tr>
            <tr>
              <td>SO NO.</td>
              <td>{selectedSO.sales_order_number}</td>
            </tr>
            <tr>
              <td>Line Item</td>
              <td>{selectedSO.name}</td>
            </tr>
            <tr>
              <td>Customer</td>
              <td>{selectedSO.customer_name}</td>
            </tr>
            <tr>
              <td>Quantity</td>
              <td>
                {selectedSO.base_quantity} {selectedSO.base_unit}
              </td>
            </tr>
            <tr>
              <td>Description</td>
              <td className="text-wrap">{selectedSO.description}</td>
            </tr>
            <tr>
              <td>Shade</td>
              <td>{selectedSO.shade}</td>
            </tr>
            <tr>
              <td>Size</td>
              <td>{selectedSO.size}</td>
            </tr>
          </tbody>
        </Table>
      </div>:null}
    </Row>
  );
}
