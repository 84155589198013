import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/action-types';

export const initialState = {
    downloadLink: null,
    errors: null,
    msg: null,
    pdfGenLoading: false
};

const printQRPDFStart = (state, action) => updateObject(state, {
    pdfGenLoading: true
})

const printQRPDFSuccess = (state, action) => updateObject(state, {
    pdfGenLoading: false
})

const printQRPDFFail = (state, action) => updateObject(state, {
    pdfGenLoading: false
})

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PRINT_QR_PDF_START:
            return printQRPDFStart(state, action)
        case actionTypes.PRINT_QR_PDF_SUCCESS:
            return printQRPDFSuccess(state, action)
        case actionTypes.PRINT_QR_PDF_FAIL:
            return printQRPDFFail(state, action)

        default:
            return state;
    }
};
