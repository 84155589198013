/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProcessImage from '../assets/img/brand/infographics.png';
import Brand from "../assets/img/brand/welspun_w_logo.png";
import { default as SidebarLogo } from '../assets/img/brand/weltrak_logo.png';
import Login from '../views/auth/LoginDarkTheme';
import './AuthDarkTheme.css';

class AuthDarkTheme extends React.Component {
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  loginForm = () =>  <Switch>
  <Route path='/auth/login' component={() => <Login />} />
</Switch>
  render() {
    return (
      <>
       <div className="d-flex">
      <div className="sidebar bg-welspun-theme-primary d-lg-block d-sm-none d-none">
        <div align='center' className="pt-4 text-center sidebar-logo"> 
           <img alt='Logo' width="220" src={SidebarLogo} />
        </div>
        <div  className="login-modal pb-2">
        {this.loginForm()}
          
          <div className="text-center py-2">
          <img src={Brand} width="100" alt="welspun" />
        </div>
        </div>
        
      </div>
      <div className="main">
        <div className="section-right-top">
            <div className="banner-text  h1">
                Where it comes from matters.
            </div>
        </div>
        <div className="d-lg-none d-sm-block">
            <div  className="row section-right-bottom">
              <div className="offset-sm-1 col-sm-10 col-md-8 offset-md-2 mb-5 bg-welspun-theme-primary mt-4 border-1 rounded">
                
              <div align="center" className="text-center mt-3">  <img alt='Logo' width="200" src={SidebarLogo} /> </div>
              {this.loginForm()}
              <div className="text-center mb-3">
          <img src={Brand} width="100" alt="welspun" />
        </div>
              </div>
              </div>
        </div>
        <div className="row section-right-bottom">
            <div className="bottom-left text-center col-lg-6 col-12">
                <p className="h1 text-uppercase text-welspun-theme-primary">the absolute platinum-standard</p>
                <p className="m-5  text-justify h3 font-weight-normal text-welspun-theme-primary">Wel-Trak&trade; 2.0 with Blockchain technology is the most trustworthy traceability solution in the marketplace that will guarantee, with complete visibility, that the product the consumer buys is the product that is promised.
                <br/><br /> The result is an impenetrable vault of information.</p>
                {/* <p></p> */}
                <p>Click to know more. <a className="text-welspun-theme-primary font-weight-bold" href="http://wel-trak.com/" target="_blank" alt="weltrak">Wel-Trak.com</a></p>
            </div>
            <div className="bottom-right col-lg-6 col-12 mt-4">
                <div className="infographics " align="center">
                    <img className="info-image " src={ProcessImage} alt='info' />
                </div>
            </div>
        </div>
        <div className="section-footer float-right mt-5 mr-5 font-weight-bold">
                <a href="https://www.infinichains.com" className="text-welspun-theme-primary" target="_blank">Powered by Infinichains &copy;</a>
      </div>
      </div>
      
    </div>
        
      </>
    );
  }
}

export default AuthDarkTheme;
