import React from 'react';

export const updateObject = (oldObject, newProperties) => ({
  ...oldObject,
  ...newProperties
});

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const headers = () => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getFromLocalStorage("token")}`
    // Authorization: 1
  }
});

export const saveInLocalStorage = (varname, value) => {
  localStorage.setItem(btoa(varname), btoa(value))
  //localStorage.setItem((varname), (value))

}

export const removeFromLocalStorage = (varname) => {
  localStorage.removeItem(btoa(varname))
  //localStorage.removeItem((varname))

}

export const getFromLocalStorage = (varname) => {
  // console.log(atob(localStorage.getItem(btoa(varname))))
  return atob(localStorage.getItem(btoa(varname)))
  //return (localStorage.getItem((varname)))

}

export const departmentFieldNames = (deptName) => {
  switch (deptName) {
    case 'cotton':
      return {
        batchField: 'Bale Lots',

        tableField: 'Bale Lot'
      }
    case 'spinning':
      return {
        batchField: 'Yarn Batches',

        tableField: 'Yarn Batch'
      }
    case 'yarn':
      return {
        batchField: 'Yarn Batches',
        tableField: 'Yarn Batch'
      }
    case 'weaving':
      return {
        batchField: 'Beam Batches',
        tableField: 'Beam Batch'
      }
    case 'processing':
      return {
        batchField: 'Dye Lots',
        tableField: 'Dye Lot'
      }
    case 'cut_and_sew':
      return {
        batchField: 'Dye Lots',
        tableField: 'Dye Lot'
      }
    default:
      return {
        batchField: 'Batches',
        tableField: 'Batch'
      }
  }
}

export const backendRoutes = {
  register: '/api/register',
  login: '/api/login',
  getAllUsers: '/api/users',
  checkToken: '/api/checktoken',
  forgotPassword: '/api/forgotpassword',
  getAllUserDepartments: (userId) => `/api/users/${userId}/departments`,
  getAllDepartmentsByUser: (loggedInUserId) => `api/users/${loggedInUserId}/departments`,
  getDepartmentById: (deptId) => `api/departments/${deptId}`,
  getDepartmentByIdforUser: (deptId, userId) => `/api/users/${userId}/departments/${deptId}`,
  getBatchByIdforUser: (batchId, deptId) => `/api/departments/${deptId}/batches/${batchId}`,
  addNewActivity: (batchId) => `api/batches/${batchId}/activities`,
  approveBatchByNumber: (batchNumber) => `/api/batches/${batchNumber}/approve`,
  exportBatchByStageName: (stageName) => `/api/batches/${stageName}/export`,
  getTraceabilitySO: (soNumber) => `api/sales_order/${soNumber}/drilldown`,
  getAllSO: `api/sales_order`,
  getBalesByNumber: (baleLotNumber) => `/api/batches/${baleLotNumber}`,
  printQRCodePDF: (so_line_item_id) => `/api/sales_order/qr_code/${so_line_item_id}/print/`,
  generateQrCode: (soLineItem) => `/api/sales_order/${soLineItem}`,
  getDocuments: `/api/certificates`,
  downloadDocuments: (queryParams) => encodeURI(`/api/files/?url=${queryParams}`)
}

export const messageList = {
  error: 'Oops! There seems to be some error! Please try again.'
}

export const simpleErrorMsg = (message) => {
  return <div className="text-danger h5">
      <span>{message}</span>
    </div>
}

export const getBase64Image = (img) => {
  let imgImage = document.createElement('img');
  imgImage.src=img.src;
  var canvas = document.createElement("canvas");
  canvas.width = imgImage.width;
  canvas.height = imgImage.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(imgImage, 0, 0);
  // var dataURL = canvas.toDataURL("image/png");
  return canvas.toDataURL("image/png");
  // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

export const imageProcess = (src, ctx, x,y,vx,vy) => {
  return new Promise((resolve,reject)=>{
    let img = new Image();
    img.src = src;
    img.onload = function(){
      ctx.drawImage(img,x,y,vx,vy);
      resolve(ctx)
    }
  })
}

export const permissionsManager = (role) => {
  switch(role) {
    case 'viewer': 
    return 1
    case 'operator':
      return 2
    case 'approver':
      return 3
    default:
      return 0
  }
}

export const titleCaseConverter = (string) => string.replace(string[0],string[0].toUpperCase())