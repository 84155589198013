import { api } from '../../axios-instance';
import { backendRoutes, headers } from '../../shared/utility';
import * as actionTypes from './action-types';
import { breadCrumb, getBatchByIdSuccess } from './departments';

export const addNewActivityStart = _ => ({
  type: actionTypes.ADD_NEW_ACTIVITY_START
});

export const addNewActivitySuccess = activity => ({
  type: actionTypes.ADD_NEW_ACTIVITY_SUCCESS,
  activity
});

export const addNewActivityFail = (error, msg) => ({
  type: actionTypes.ADD_NEW_ACTIVITY_FAIL,
  error,
  msg
});

export const addNewActivity = (batchId, data) => {
  return dispatch => {
    dispatch(addNewActivityStart());
    api
      .post(backendRoutes.addNewActivity(batchId), data, headers())
      .then(response => {
        dispatch(addNewActivitySuccess(response.data.result.payload));
        dispatch(getBatchByIdSuccess(response.data.result.payload));
        dispatch(breadCrumb('Batch', response.data.result.payload.id));
      })
      .catch(error => {
        dispatch(addNewActivityFail(error, error.message));
      });
  };
};
