import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Table
} from 'reactstrap';
import QRCode from 'qrcode.react';
import { qrUrl } from '../../axios-instance';
import Loader from '../../shared/Loader/Loader';
import Error from '../../shared/Error/Error';
import * as actions from '../../store/actions/index';
import WelspunLogo from '../../assets/img/brand/welspun_india_logo.png';
import BottomText from '../../assets/img/brand/bottom_text.png';
import Echart from './Echart';
import SoTable from './SoTable';
import './Traceability.css';
import {
  messageList,
  getBase64Image,
  imageProcess
} from '../../shared/utility';
import SearchBar from '../../shared/Filters/SearchBar';
function SOTraceability() {
  const dispatch = useDispatch();
  const [so, setSo] = useState();
  const [qrModal, setQrModal] = useState(false);
  const [hasQr, setHasQr] = useState(false);
  const [selectedLineItem, setSelectedLineItem] = useState();
  const [generateLineItem, setGenerateLineItem] = useState();
  const [displayList, setDisplayList] = useState([]);
  const [imageDetails, setImageDetails] = useState({
    name: '',
    base64: '',
    size: '',
    displayName: ''
  });
  const [isGenerateQRWorking, setIsGenerateQRWorking] = useState(false);
  const [showError, setShowError] = useState(false);

  const [imageError, setImageError] = useState({ error: false, errorText: '' });

  const myDivToFocus = createRef();
  const printMe = createRef();

  useEffect(() => {
    dispatch(actions.getAllSO());
  }, []);

  const soList = useSelector(state => state.traceability.allSO);
  const allSoLoading = useSelector(state => state.traceability.loading);
  const allSoSuccess = useSelector(state => state.traceability.success);
  const allSoError = useSelector(state => state.traceability.errors);
  const gQrLoading = useSelector(state => state.traceability.generateQrLoading);
  const gQrSuccess = useSelector(state => state.traceability.generateQrSuccess);
  const gQrError = useSelector(state => state.traceability.generateQrErrors);

  const pdfGenLoading = useSelector(
    state => state.soTraceability.pdfGenLoading
  );

  useEffect(() => {
    setDisplayList(soList);
  }, [soList]);

  useEffect(() => {
    if (so)
      setTimeout(() => {
        if (myDivToFocus && myDivToFocus.current)
          myDivToFocus.current.scrollIntoView({
            behavior: 'smooth'
          });
      }, 300);
  }, [myDivToFocus, so]);

  useEffect(() => {

    if (isGenerateQRWorking) {
      setIsGenerateQRWorking(false);
      dispatch(actions.generateQrCode(generateLineItem, [imageDetails]));
    }

    else if (!gQrLoading && gQrSuccess) {
      // setGenerateLineItem(null);
      // setCheckedValue(false)
      if(qrModal)
      toggleQrModal();
    }
    else if (!gQrLoading && gQrError) {
      // setGenerateLineItem(null);
    }
  }, [isGenerateQRWorking,gQrLoading, gQrSuccess, gQrError]);


  const selectedSo = (so, hasQr) => {
    if (hasQr) {
      setSo(so);
    } else {
      setSo('');
      toggleErrorModal();
    }
  };

  const toggleErrorModal = () => {
    setShowError(!showError);
  };

  const qrAction = (qrStatus, selectedLineItem) => {
    setHasQr(qrStatus);
    setSelectedLineItem(selectedLineItem);
    toggleQrModal();
  };
  const toggleQrModal = () => {
    setQrModal(!qrModal);
    setSo('');
    setImageDetails({ name: '', base64: '', size: '', displayName: '' });
    setImageError({ error: false, errorText: '' });
  };

  const getFile = e => {
    e.preventDefault();

    let reader = new FileReader();
    if (e.target.files[0]) {
      let file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (file.type.includes('image/')) {
          setImageDetails({
            name: file.name,
            base64: reader.result,
            size: file.size,
            displayName:
              file.name.length > 25
                ? file.name.slice(0, 24) + ' ...'
                : file.name
          });
          setImageError({ error: false, errorText: '' });
        } else {
          setImageDetails({
            name: '',
            base64: '',
            size: '',
            displayName: ''
          });
          setImageError({ error: true, errorText: 'Not a valid Image' });
        }
      };
    }
  };

  const triggerUpload = () => {
    let uploadDoc = document.getElementById('uploadImage');
    uploadDoc.click();
  };

  const updateDisplayList = list => {
    setDisplayList(list);
    setSo('');
  };


  const generateQR = lineItem => {
    setGenerateLineItem(lineItem);
    setIsGenerateQRWorking(true)
  };

  const downloadQR = async fileName => {
    const qrCode = document.getElementById('qrCode');
    const qrTop = document.getElementById('qrTop');
    const qrBottom = document.getElementById('qrBottom');
    const qrCodeUrl = qrCode.toDataURL('image/png');
    const qrTopUrl = getBase64Image(qrTop);
    const qrBottomUrl = getBase64Image(qrBottom);

    let canvas = document.createElement('canvas');
    canvas.width = '460';
    canvas.height = '630';
    let context = canvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);
    imageProcess(qrTopUrl, context, 20,20,420,53).then(response => {
      imageProcess(qrCodeUrl, response, 20, 103, 420, 420).then(response => {
        imageProcess(qrBottomUrl, response, 20, 550, 420, 42).then(response => {
          context = response;
          const pngUrl = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');
          let downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = `${fileName}.png`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      });
    })
  };

  return (
    <React.Fragment>
      <Container fluid className='pb-5'>
        {allSoLoading ? (
          <Loader />
        ) : !allSoLoading && allSoSuccess ? (
          <div>
            <span className='clearfix' />
            <Row>
              <Col lg='9' md='9' sm='12' xs='12' className='text-left'>
                <span className='h1 mb-2 text-primary font-weight-bold text-capitalize'>
                  Sales order Traceablity
                </span>
              </Col>
            </Row>
            <div className='pb-4'></div>
            <Row>
              <Col lg='5' md='6' sm='8' xs='9'>
                <SearchBar
                  searchArray={soList}
                  searchColumn={['name','customer_po_number']}
                  updateDisplayList={updateDisplayList}
                  placeholderText={'SO Line Item, Customer PO' }
                />
              </Col>
            </Row>
            <div className='pb-4'></div>
            <Row>
              <Col>
                {soList && soList.length ? (
                  <SoTable
                    soList={displayList}
                    selectedSo={selectedSo}
                    qrAction={qrAction}
                  />
                ) : null}
              </Col>
            </Row>
            <div className='pb-4' ref={myDivToFocus}></div>
            <Row>
              <Col>
                <Echart so={so} />
              </Col>
            </Row>
          </div>
        ) : !allSoLoading && allSoError ? (
          <Error message={messageList.error} />
        ) : null}
      </Container>
      <Modal isOpen={qrModal} toggle={toggleQrModal} size={hasQr ? 'md' : 'lg'}>
        <ModalHeader toggle={toggleQrModal}>
        <span className="h3">{hasQr ? 'View QR' : 'Generate QR'}</span>
        </ModalHeader>
        <ModalBody>
          {hasQr ? (
            <>
              <Row>
                <Col>
                  <div align='center'>
                    <div className='upload-div pt-3'>
                      <span className='font-weight-bold text-center'>
                        {selectedLineItem.name}
                      </span>
                      <div className='my-3' ref={printMe}>
                      <div className="mb-1">
                          <img
                            id='qrTop'
                            src={WelspunLogo}
                            style={{ width: '130px', height: '16px' }}
                            alt='selected qr'
                            // width='130px'
                          />
                        </div>
                        <QRCode
                          value={`${qrUrl}/?qrcode=${selectedLineItem.qr_journey}`}
                          size={400}
                          id='qrCode'
                          style={{ height: '130px', width: '130px' }}
                        />
                        <div>
                          <img
                            id='qrBottom'
                            src={BottomText}
                            style={{ width: '130px', height: '13px' }}
                            alt='bottom text'
                            // width='130px'
                          />
                        </div>
                      </div>
                      {pdfGenLoading ? (
                        <div className=' mt-1 text-uppercase text-primary showpointer h4 mr-auto qrLoader'>
                          <div className="row qrLoader">
                            <Loader text={"Generating PDF"}/>
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex'>
                          <div
                            className=' ml-5 text-uppercase text-primary showpointer h4 mr-auto'
                            onClick={() => {
                              dispatch(
                                actions.printQRPDF(selectedLineItem.name)
                              );
                            }}
                          >
                            Print
                          </div>
                          <div
                            className=' mr-5 text-uppercase text-dark showpointer h4 ml-auto'
                            onClick={() => {
                              downloadQR(selectedLineItem.qr_journey);
                            }}
                          >
                            Download
                          </div>
                        </div>
                      )}
                      {/* <div className="d-flex col px-5">
                      <div
                        className=' mt-1 text-uppercase text-primary showpointer h4 mr-auto'
                        onClick={() => {
                          printQR(selectedLineItem.name);
                        }}
                      >
                        Print
                      </div>
                      <div
                        className=' mt-1 text-uppercase text-dark showpointer h4 ml-auto'
                        onClick={() => {
                          downloadQR(selectedLineItem.name);
                        }}
                      >
                        Download
                      </div>
                      </div> */}
                    </div>
                    <div align='center'></div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              {selectedLineItem ? (
                <Row>
                  <Col>
                    <Table borderless>
                      <tbody>
                        <tr>
                          <th>SO No.</th>
                          <td>{selectedLineItem.sales_order_number}</td>
                        </tr>
                        <tr>
                          <th>Line Item</th>
                          <td>{selectedLineItem.name}</td>
                        </tr>
                        <tr>
                          <th>Color</th>
                          <td>{selectedLineItem.shade}</td>
                        </tr>
                        <tr>
                          <th>Size</th>
                          <td>{selectedLineItem.size}</td>
                        </tr>
                        <tr>
                          <th>Customer Name</th>
                          <td>{selectedLineItem.customer_name}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    <div align='center'>
                      <div className='upload-div' id='qr-code'>
                        <div style={{ height: '190px' }}>
                          {imageDetails.base64 ? (
                            <>
                              <div className='image-holder mt-4'>
                                <img
                                  className="imageHolder"
                                  src={imageDetails.base64}
                                  alt='selected QR'
                                  height='150px'
                                />
                              </div>
                              <span className='text-left mt-3'>
                                {imageDetails.displayName}
                              </span>
                            </>
                          ) : (
                            <>
                              <img
                                src={require('../../assets/img/icons/camera.png')}
                                alt='camera-icon'
                                width='53px'
                                className='py-4 mt-5'
                              />
                              <span className='d-block text-danger h5'>
                                {imageError.errorText}
                              </span>
                            </>
                          )}
                        </div>
                        <div align='center'>
                          <input
                            type='file'
                            hidden
                            id='uploadImage'
                            onChange={getFile}
                          />
                          <Button
                            type='button'
                            className={`btn-border-style-outline btn-outline-primary text-uppercase ${
                              imageDetails.base64 ? 'mt-2' : 'mt-4'
                            }`}
                            onClick={triggerUpload}
                          >
                            {imageDetails.base64?'Add Different Image' :'Add Product Image'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </>
          )}
          <div align='center'>
            {hasQr ? (
              <Button
                type='button'
                color='primary'
                className='btn-border-style-outline btn-outline-primary text-uppercase'
                style={{ height: 'inherit' }}
                onClick={() => {
                  window.open(
                    `${qrUrl}/?qrcode=${selectedLineItem.qr_journey}`,
                    '_blank'
                  );
                }}
              >
                show user journey
              </Button>
            ) : (
              <div>
                <div className='d-flex'>
                  <div className='d-inline-flex py-4'>
                    {/* <div>
                      <label className='custom-toggle'>
                        <input
                          type='checkbox'
                          onChange={e => setCheckedValue(e.target.checked)}
                        />
                        <span className='custom-toggle-slider rounded-circle' />
                      </label>
                    </div> */}
                    <div className='ml-2 font-weight-bold text-warning'>
                      Generating QR will write this data permanently to the blockchain.
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    color='primary'
                    className='btn-border-style text-uppercase'
                    style={{ height: 'inherit' }}
                    disabled={imageError.error /*|| !checkedValue*/ || gQrLoading}
                    onClick={() => {
                      generateQR(selectedLineItem.name);
                    }}
                  >
                    Generate QR Code
                  </Button>
                  {gQrLoading?<div style={{paddingLeft:'41%'}}>

                    <Loader text={"Generating"} />
                  </div>:null}

                  {!gQrLoading && gQrError?
                  <Container className="mt-2">
                    <Error message={messageList.error} dismissible={true} colSize={'col-10'} offset={'offset-1'} />
                  </Container>

                   :null  }
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={showError} toggle={toggleErrorModal}>
        <ModalHeader toggle={toggleErrorModal}>QR Not Generated</ModalHeader>
        <ModalBody>
          <div className='mt-2'>
            <span className='text-warning font-weight-bold'>
              You need to Generate QR first.
            </span>
          </div>
          <div className='mt-2'>
            Drilldown is not created for this line item. Generate QR to view this drilldown.
          </div>
        </ModalBody>
        <ModalFooter className='bg-welspun-secondary'>
          <Button color='white' onClick={toggleErrorModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
export default React.memo(SOTraceability);
