const Validators = {
  fieldRequired: (field, length = 2) => {
    return field && field.length > length ? true : false;
  },

  fieldIsDate: field => {
    return typeof field.getMonth === "function" ? true : false;
  },

  email: field => {
    return new RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    ).test(field);
  },
  fieldMatch: (initialValue, confirmValue) => initialValue && confirmValue && initialValue === confirmValue
};

export default Validators;
