import * as actionTypes from './action-types';
import { api } from '../../axios-instance';
import {
  headers,
  backendRoutes
} from '../../shared/utility';
import {breadCrumbSuccess} from './departments';

export const getCertificateDocumentsStart = _ => ({
  type: actionTypes.GET_CERTIFICATE_DOCUMENTS_START
});

export const getCertificateDocumentsSuccess = documents => ({
  type: actionTypes.GET_CERTIFICATE_DOCUMENTS_SUCCESS,
  documents
});

export const getCertificateDocumentsFail = (error, msg) => ({
  type: actionTypes.GET_CERTIFICATE_DOCUMENTS_FAIL,
  error,
  msg
});

let documentList = [
  {
    type: 'oritain',
    documents: [{
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'27/11/2019',
      uploaded_date: '10/12/2019',
      user: {
        name: 'Rahul Chiplunker',

      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    
  ]
  },
  {
    type: 'gots',
    documents: [{
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'27/11/2019',
      uploaded_date: '10/12/2019',
      user: {
        name: 'Mukul Sahay',

      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    {
      plant_name:'Anjar Plant, Gujarat',
      status: 'finished',
      type: 'cotton',
      issue_date:'20/11/2019',
      uploaded_date: '02/12/2019',
      user: {
        name: 'Mukul Sahay',
        
      }
    },
    
  ]
  }
]

export const getCertificateDocuments = () => {
  return dispatch => {
    dispatch(getCertificateDocumentsStart());
    api
      .get(backendRoutes.getDocuments, headers())
      .then(response => {
        let { payload } = response.data.result;
        console.log(payload)
        dispatch(getCertificateDocumentsSuccess(payload));
        dispatch(breadCrumbSuccess('Departments', null, 0, 0, undefined));
      })
      .catch(error => {
        dispatch(getCertificateDocumentsFail(error, error.message));
        dispatch(breadCrumbSuccess('Departments', null, 0, 0, undefined));
      });
  };
};
