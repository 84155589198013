import * as actionTypes from '../actions/action-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  success: null,
  loading: false,
  error: null,
  msg: null,
  allActivities: []
};

const addNewActivityStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    success: true,
    allActivities: []
  });
};
const addNewActivitySuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    allActivities: action.allActivities
  });
};

const addNewActivityFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    msg: action.msg,
    loading: false,
    expiry: null,
    allActivities: []
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NEW_ACTIVITY_START:
      return addNewActivityStart(state, action);
    case actionTypes.ADD_NEW_ACTIVITY_SUCCESS:
      return addNewActivitySuccess(state, action);
    case actionTypes.ADD_NEW_ACTIVITY_FAIL:
      return addNewActivityFail(state, action);
    default:
      return state;
  }
};
