import React, { Component } from 'react';
import Moment from 'react-moment';
import Paginate from '../../shared/Pagination/Paginate';
import { connect } from 'react-redux';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import Error from '../../shared/Error/Error';
import SearchAndFilter from '../../shared/Filters/SearchAndFilter';
import getAssetImage from '../../shared/ImageAssets';
import Loader from '../../shared/Loader/Loader';
import { messageList } from '../../shared/utility';
import * as actions from '../../store/actions/index';
import './DepartmentSummary.css';
class DepartmentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_headers: [],
      table_data: [],
      batchNamesArray: [],
      batchesArray: [],
      filteredDisplayList: [],
      unfilteredDisplayList: [],
      selectedTab: 0,
      openFilter: false,
      currentPage: 1,
      postsPerPage: 10,
      pageCount: 0,
      currentBatches: [],
      resetFields: false
    };
  }

  componentDidMount() {
    this.props.onGetDepartmentSummary(this.props.match.params.department_id);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let indexOfLastPost = prevState.currentPage * prevState.postsPerPage;
    let indexOfFirstPost = indexOfLastPost - prevState.postsPerPage;
    if (
      Object.keys(nextProps.departmentSummary).length &&
      nextProps.departmentSummary.batches !== prevState.table_data
    ) {
      let separatedBatches = {};
      if (nextProps.departmentSummary.batches.length)
        separatedBatches = batchSeparator(nextProps.departmentSummary.batches);
      return {
        pageCount: separatedBatches.batchesArray
          ? Math.ceil(
              separatedBatches.batchesArray[0].length / prevState.postsPerPage
            )
          : 0,
        unfilteredDisplayList: separatedBatches.batchesArray
          ? separatedBatches.batchesArray[0]
          : [],
        filteredDisplayList: separatedBatches.batchesArray
          ? separatedBatches.batchesArray[0]
          : [],
        table_data: nextProps.departmentSummary.batches,
        currentBatches: separatedBatches.batchesArray
          ? separatedBatches.batchesArray[0].slice(
              indexOfFirstPost,
              indexOfLastPost
            )
          : 0,
        batchesArray: separatedBatches.batchesArray
          ? separatedBatches.batchesArray
          : [],
        batchNamesArray: separatedBatches.batchNamesArray
          ? separatedBatches.batchNamesArray
          : []
      };
    } else return {};

    function batchSeparator(targetArray) {
      //get names of all batches that will be used to display as horizontal navbar tabs in UI
      let batchNamesArray = targetArray.length
        ? targetArray.reduce((final, initial) => {
            if (final.indexOf(initial.stage) === -1) {
              final.push(initial.stage);
              return final;
            } else return final;
          }, [])
        : [];

      //create an array that will be of the same size as the number of batch names & populate with data of that batch.
      let batchesArray = new Array(batchNamesArray.length);
      targetArray.map(item => {
        for (let i = 0; i < batchNamesArray.length; i++) {
          if (item.stage === batchNamesArray[i]) {
            if (batchesArray[i] && batchesArray[i].length) {
              let tempArr = batchesArray[i];
              tempArr.push(item);
              batchesArray[i] = tempArr;
            } else batchesArray[i] = [item];
          }
        }
        return null;
      });
      return { batchNamesArray, batchesArray };
    }
  }

  exportDeptData = () => {
    this.props.onGetBatchReport(
      this.state.batchNamesArray[this.state.selectedTab]
    );
  };

  handleBatchList = (index = this.state.selectedTab) => {
    this.setState(
      {
        selectedTab: index,
        unfilteredDisplayList: this.state.batchesArray[index],
        filteredDisplayList: this.state.batchesArray[index],
        resetFields: true
      },
      () => {
        this.paginate(1);
      }
    );
  };

  resetFieldsComplete = () => {
    this.setState({ resetFields: false });
  };

  paginate = pageNumber => {
    this.setState(
      {
        currentPage: pageNumber.selected ? pageNumber.selected + 1 : pageNumber
      },
      () => {
        this.resetCurrentBatches(
          this.state.filteredDisplayList.length
            ? this.state.filteredDisplayList
            : this.state.unfilteredDisplayList
        );
      }
    );
  };

  resetCurrentBatches = targetList => {
    let indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    let indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    this.setState({
      pageCount: Math.ceil(targetList.length / this.state.postsPerPage),
      currentBatches: targetList.slice(indexOfFirstPost, indexOfLastPost)
    });
  };

  handleClick = data => {
    this.props.history.push(
      `/admin/departments/${this.props.match.params.department_id}/batches/${data['id']}`
    );
  };

  updateDisplayList = searchFilteredList => {
    this.setState(
      { filteredDisplayList: searchFilteredList, currentPage: 1 },
      () => {
        this.resetCurrentBatches(searchFilteredList);
      }
    );
  };

  render() {
    let { currentBatches, batchNamesArray } = this.state;

    let { departmentSummary, deptLoading, deptSuccess, deptError } = this.props;

    return (
      <React.Fragment>
        <Container fluid className='pb-5'>
          {deptLoading ? (
            <Loader />
          ) : !deptLoading && deptSuccess ? (
            <>
              <Row>
                <Col lg='9' md='9' sm='12' xs='12'>
                  <div className='float-left mb-2 '>
                    {departmentSummary.name ? (
                      <img
                        src={getAssetImage(
                          departmentSummary.name
                            .toLowerCase()
                            .replace(/ /g, '_')
                        )}
                        alt='dept'
                        width='50px'
                      />
                    ) : null}
                    <span className='h1 ml-4 text-primary font-weight-bold text-capitalize text-left underline'>
                      {departmentSummary.name
                        ? `Department: ${departmentSummary.name}`
                        : ''}
                    </span>
                  </div>
                </Col>
                <Col lg='9' md='9' sm='12' xs='12'>
                  <span className='h4 mb-2 text-default text-capitalize float-left'>
                    {departmentSummary.description
                      ? `Description: ${departmentSummary.description}`
                      : ''}
                  </span>
                </Col>
              </Row>
              <div className='pb-4'></div>
              <SearchAndFilter
                handleBatchList={this.handleBatchList}
                searchArray={this.state.unfilteredDisplayList}
                searchColumn={'batch_number'}
                updateDisplayList={this.updateDisplayList}
                placeholderText={
                  this.state.batchNamesArray[this.state.selectedTab]
                }
                resetFields={this.state.resetFields}
                resetFieldsComplete={this.resetFieldsComplete}
                selectSearchColumn={'approval_status'}
                dateSearchColumn={'creation_date'}
              />
              <div className='pb-5'></div>
              <Row>
                <Col lg='12' md='12' xl='9'>
                  {/* {batchNamesArray.length > 1? */}
                  <ul className='nav nav-tabs'>
                    {batchNamesArray.map((name, i) => (
                      <li className='nav-item bg-white' key={i}>
                        <span
                          className={`nav-link showpointer bg-white px-4 text-uppercase h3 mb-0 font-weight-normal ${
                            this.state.selectedTab === i
                              ? 'font-weight-bold text-primary set-border'
                              : ''
                          } `}
                          onClick={() => {
                            this.handleBatchList(i);
                          }}
                        >
                          <span>{name}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                  {/* :null} */}

                  <div className='container-fluid bg-white rounded shadow'>
                    <div className='p-4'>
                      <Row>
                        <Col>
                          <span className='h3 float-left'>
                            {/* {this.state.batchNamesArray[this.state.selectedTab]} */}
                            {/* {departmentSummary.name
                          ? departmentFieldNames(
                            departmentSummary.name
                              .toLowerCase()
                              .replace(/ /g, '_')
                          ).batchField
                          : 'Batches'} */}
                          </span>
                        </Col>
                        <Col>
                          <Button
                            className='btn-border-style-outline btn-outline-primary m-0 float-right'
                            type='button'
                            onClick={this.exportDeptData}
                          >
                            Export Data
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Table striped responsive hover>
                        <thead>
                          <tr>
                            <th>
                              {this.state.batchNamesArray[
                                this.state.selectedTab
                              ]
                                ? this.state.batchNamesArray[
                                    this.state.selectedTab
                                  ]
                                : 'Batches'}
                              {/* {departmentSummary.name
                            ? departmentFieldNames(
                              departmentSummary.name
                                .toLowerCase()
                                .replace(/ /g, '_')
                            ).tableField
                            : 'Batch'} */}
                            </th>
                            <th>Created At</th>
                            <th>Approval Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentBatches.length ? (
                            currentBatches.map((data, index) => (
                              <tr
                                key={index}
                                className='showpointer'
                                onClick={() => this.handleClick(data)}
                              >
                                <td>{data.batch_number}</td>
                                <td>
                                  <Moment format='DD/MM/YYYY'>
                                    {data.creation_date}
                                  </Moment>
                                </td>
                                <td className='text-capitalize'>
                                  {data.approval_status.toLowerCase() ===
                                  'pending' ? (
                                    <>
                                      <span className='text-danger pr-2'>
                                        &bull;
                                      </span>
                                      {data.approval_status}
                                    </>
                                  ) : (
                                    <>
                                      <span className='text-success pr-2'>
                                        &bull;
                                      </span>
                                      {data.approval_status}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan='3'>
                                <strong>Not Found</strong>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {this.state.pageCount > 1 ? (
                        <Paginate
                          currentPage={parseInt(this.state.currentPage)}
                          paginate={this.paginate}
                          targetArrayLength={
                            this.state.filteredDisplayList.length
                          }
                        />
                      ) : null}
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          ) : !deptLoading && deptError ? (
            <Error message={messageList.error} />
          ) : null}
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  departmentSummary: state.departments.departmentById,
  deptLoading: state.departments.loading,
  deptSuccess: state.departments.success,
  deptError: state.departments.errors,
  deptMsg: state.departments.msg,
  batchFileSuccess: state.departments.exportReportSuccess,
  batchFileLoading: state.departments.exportReportLoading,
  batchFileError: state.departments.exportReportErrors,
  batchFileMsg: state.departments.exportReportMsg,
  batchReport: state.departments.report
});
const mapDispatchToProps = dispatch => ({
  onGetDepartmentSummary: id => dispatch(actions.getDepartmentById(id)),
  onGetBatchReport: stageName =>
    dispatch(actions.exportBatchListFile(stageName))
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentSummary);
