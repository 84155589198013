import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Col,
  Label
} from 'reactstrap';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import moment from 'moment';

export default function SearchAndFilter(props) {
  let {
    searchArray,
    searchColumn,
    updateDisplayList,
    placeholderText,
    selectSearchColumn,
    dateSearchColumn,
    resetFields,
    resetFieldsComplete
  } = props;
  const [searchText, setSearchText] = useState('');
  const dropDownOptions = ['All', 'Pending', 'Approved'];
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [std, setStd] = useState();
  const [edd, setEdd] = useState();
  const [ssCol, setssCol] = useState();

  useEffect(() => {
    if (resetFields) {
      setSearchText('');
      setSelectedFilter('all');
      setStd(undefined);
      setEdd(undefined);
      setssCol('');
      resetFieldsComplete();
    }
  }, [resetFields, resetFieldsComplete]);
  const searchFilterFunction = (targetArray, targetColumn, searchtext) =>
    targetArray.filter(row =>
      row[targetColumn]
        .toString()
        .toLowerCase()
        .includes(searchtext.toLowerCase())
    );

  const searchByText = (value, searchArray) => {
    setSearchText(value);
    if (value) {
      return searchFilterFunction(searchArray, searchColumn, value);
    } else {
      return searchArray;
    }
  };

  const selectFilterFunction = (
    targetValue,
    defaultTargetValue,
    targetArray,
    targetColumn
  ) =>
    targetValue.toLowerCase() === defaultTargetValue.toLowerCase()
      ? targetArray
      : !targetColumn
      ? targetArray
      : targetArray.filter(
          row => row[targetColumn].toLowerCase() === targetValue.toLowerCase()
        );

  const filterBySelect = (
    searchValue,
    defaultSearchValue,
    selectSearchColumn,
    searchArray
  ) => {
    setSelectedFilter(searchValue);
    setssCol(selectSearchColumn);
    return selectFilterFunction(
      searchValue,
      defaultSearchValue,
      searchArray,
      selectSearchColumn
    );
  };

  const dateFilterFunction = (targetStartDate, targetEndDate, targetArray) => {
    let eDate = {};
    if (targetEndDate) {
      eDate = moment(targetEndDate);
    }
    return targetStartDate && targetEndDate && targetStartDate <= targetEndDate
      ? targetArray.filter(
          item =>
            new Date(item[dateSearchColumn]) > targetStartDate._d &&
            new Date(item[dateSearchColumn]) <= eDate._d
        )
      : targetArray;
  };

  const filterByDateRange = (startDate, endDate, searchArray) => {
    setStd(startDate);
    setEdd(endDate);
    return dateFilterFunction(startDate, endDate, searchArray);
  };

  const toggleFilter = () => setOpenFilter(!openFilter);

  const runFilters = ({
    searchValue,
    startDate,
    endDate,
    filterValue,
    defaultFilterValue,
    targetSelectColumn
  }) => {
    let searchString =
      searchValue !== undefined ? searchValue : searchText ? searchText : '';
    let sDate = startDate ? startDate : std ? std : undefined;
    let eDate = endDate ? endDate : edd ? edd : undefined;
    let fValue = filterValue
      ? filterValue
      : selectedFilter
      ? selectedFilter
      : 'all';
    let srchCol = targetSelectColumn ? targetSelectColumn : ssCol;
    let resultArray = searchByText(searchString, searchArray);
    resultArray = filterByDateRange(sDate, eDate, resultArray);
    resultArray = filterBySelect(
      fValue,
      defaultFilterValue ? defaultFilterValue : 'all',
      srchCol,
      resultArray
    );
    updateDisplayList(resultArray);
  };

  const handleFieldsReset = () => {
    props.handleBatchList();
  };

  return (
    <Row>
      <Col lg='5' md='6' sm='8' xs='9'>
        <InputGroup className='input-group-alternative border-style border-0'>
          <InputGroupAddon addonType='prepend'>
            <InputGroupText>
              <span className='fa fa-search icon-color-size' />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            autoComplete='off'
            placeholder={`Search by ${
              placeholderText ? placeholderText : 'Batch'
            }`}
            type='text'
            value={searchText}
            onChange={e => runFilters({ searchValue: e.target.value })}
          />
        </InputGroup>
      </Col>
      {dateSearchColumn ? (
        <Col lg='1' md='1' sm='4' xs='3' className='text-left'>
          <Dropdown
            isOpen={openFilter}
            toggle={toggleFilter}
            className='showpointer'
          >
            <DropdownToggle
              tag='span'
              data-toggle='dropdown'
              aria-expanded={toggleFilter}
            >
              <img
                height='60px'
                alt='...'
                src={require('../../assets/img/icons/filter_icon.png')}
              />
            </DropdownToggle>
            <DropdownMenu className='mt-4 setwidth'>
              <DropdownItem header className='text-default text-left'>
                <div className='d-flex'>
                  <span className='h4'>FILTER</span>
                  <button
                    className='btn btn-secondary btn-sm ml-auto'
                    onClick={() => {
                      handleFieldsReset();
                    }}
                  >
                    <i className='fas fa-sync-alt'></i>
                  </button>
                </div>
              </DropdownItem>

              <DropdownItem divider />
              <div className='mx-2'>
                <Row>
                  <Col lg='2' md='2'>
                    <Label className='mr-1'>Date: </Label>
                  </Col>
                  <Col lg='10' md='10' offset='2'>
                    <DateRangePicker
                      runFilters={runFilters}
                      startDate={std}
                      endDate={edd}
                    />
                  </Col>
                </Row>
              </div>

              <div className='mx-2'>
                <Row>
                  <Col lg='2' md='2'>
                    <Label className='mr-1'>Status: </Label>
                  </Col>
                  <Col lg='5' md='5' offset='2'>
                    <Input
                      onChange={e =>
                        runFilters({
                          filterValue: e.target.value,
                          defaultFilterValue: 'all',
                          targetSelectColumn: selectSearchColumn
                        })
                      }
                      type='select'
                      className='ml-1'
                      value={selectedFilter}
                      name='select'
                      id='exampleSelect'
                    >
                      {dropDownOptions.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col></Col>
                </Row>
              </div>
            </DropdownMenu>
          </Dropdown>
        </Col>
      ) : null}
    </Row>
  );
}
