/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap components
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown
} from 'reactstrap';
import { getFromLocalStorage } from '../../shared/utility';
import LogoutIcon from '../../assets/img/icons/logout.png';
import './Adminnavbar.css';
class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let loggedInUser = getFromLocalStorage('name');
    return (
      <>
        <Navbar className='navbar-top navbar-dark' expand='md' id='navbar-main'>
          <Container fluid>
            {/* <Link to="/admin/departments/index" hidden={window.location.pathname.includes("/admin/departments/index")}>
              <span
                className="h2 mb-2 text-primary font-weight-bold text-capitalize d-none d-lg-inline-block"
              ><img
                  height="20px"
                  alt="..."
                  src={require("../../assets/img/icons/chevron-left.svg")}

                />  Home </span>
            </Link>
            {window.location.pathname.includes("admin/departments/index") ? <span
              className="h1 mb-0 text-white font-weight-normal text-capitalize d-lg-inline-block pb-2"
            >All Departments</span> : null} */}

            {/* {this.props.departmentName ? <span
              className="h1 mb-0 text-white font-weight-normal text-capitalize d-none d-lg-inline-block pb-2 border-bottom"
            >
              Department: <span className="h1 text-white">{this.props.brandText || ` ${this.props.departmentName}`}</span>
            </span> : <span
              className="h1 mb-0 text-white font-weight-normal text-capitalize d-none d-lg-inline-block pb-2 border-bottom"
            >All Departments</span>} */}
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className='align-items-center d-none d-md-flex ml-auto' navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className='pr-0' nav>
                  <Media className='align-items-center'>
                    <span>
                      <img
                        alt='...'
                        src={require('../../assets/img/theme/user.png')}
                        width='80%'
                      />
                    </span>
                    <Media className='d-lg-block'>
                      <span className='mb-0 text-md font-weight-bold d-none d-lg-block text-primary '>
                        {loggedInUser}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-arrow' right>
                  <DropdownItem className='noti-title' header tag='div'>
                    <h6 className='text-overflow m-0'>Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.props.logout}>
                    <img src={LogoutIcon} alt='logout' height='20px' />
                    <span className='ml-2'>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
