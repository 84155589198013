import React, { useState, useEffect } from 'react';
import { Row, Table, Col, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Error from '../../shared/Error/Error';
import Loader from '../../shared/Loader/Loader';
import oritain_Certificate from '../../assets/img/icons/oritain _certificate_logo.png'
import * as actions from '../../store/actions/documents';
import { messageList } from '../../shared/utility';
import { downloadDocument } from '../../shared/Services';

export default function Certificates(props) {

  const [selectedTab, setSelectedTab] = useState(0);
  const [currentDocs, setCurrentDocs] = useState([]);
  const [certsNamesArray, setCertsNamesArray] = useState([]);
  const [certsArray, setCertsArray] = useState([]);
  const [isWorking, setIsWorking] = useState(true);

  let table_headers = [
    'PLANT NAME',
    'SAMPLING STAGE',
    'CLAIMED ORIGIN',
    'SAMPLE COLLECTION DATE',
    'TEST REPORT DATE',
    'UPLOADED BY',
    'ACTION'
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getCertificateDocuments());
  }, []);

  const documentList = useSelector(state => state.certDocuments.documents);
  const loading = useSelector(state => state.certDocuments.loading);
  const success = useSelector(state => state.certDocuments.success);
  const errors = useSelector(state => state.certDocuments.errors);
  useEffect(() => {
    if (!loading && success && isWorking) {
      let certNamesArray = [];
      certNamesArray = documentList.length
        ? documentList.reduce((final, initial) => {
          if (final.indexOf(initial.type) === -1) {
            final.push(initial.type);
            return final;
          } else return final;
        }, []) : [];
      let certArray = new Array(certNamesArray.length);
      setCertsNamesArray(certNamesArray);
      documentList.map(item => {
        for (let i = 0; i < certNamesArray.length; i++) {
          if (item.type === certNamesArray[i]) {
            if (certArray[i] && certArray[i].length) {
              let tempArr = [...certArray[i]];
              tempArr.push(...item.documents);
              certArray[i] = [...tempArr];
            } else certArray[i] = item.documents;
          }
        }
      });
      setCertsArray(certArray);
      setCurrentDocs(certArray[0]);
      setIsWorking(false);
    }
    else if (!loading && errors && isWorking) {
      setIsWorking(false);
    }

  })
  const handleTab = (index) => {
    setSelectedTab(index);
    setCurrentDocs(certsArray[index])
  }
  console.log(currentDocs)

  return (
    <React.Fragment>
      <Container fluid className='pb-5'>
        {loading ? (
          <Loader />
        ) : !loading && success && currentDocs ? (
          <>
            <Col lg='12' md='12' sm='12' xs='12'>
              <Row>
                <span className='h1 mb-2 text-primary font-weight-bold text-capitalize'>
                  Certificates
              </span>
              </Row>
              <Row>
                <div align="center" className="col-lg-2 col-2 col-md-12 col-sm-12 col-12 pb-4 d-md-block d-lg-inline-flex flex-wrap d-block align-items-center">
                  <img src={oritain_Certificate} className="" width="100" alt="..." />
                </div>
                <div className="col-lg-10 col-10 col-md-12 col-sm-12 col-12">
                  <div className="card-body pt-0">
                    <p className="card-text font-weight-normal text-justify h2">
                    The Certification represents third party lab verification of claimed origin of raw material (cotton)by fingerprint metrics analysis.
                        <br></br>
                      <br></br>
                      Welspun has collaborated with Oritain for independent, scientific sampling and analysis of raw material for origin verification.
                      </p>
                  </div>
                </div>
              </Row>
            </Col>
            <Row>
              <Col lg='12' md='12' xl='12'>
                <ul className='nav nav-tabs'>
                  {certsNamesArray.map((name, i) => (
                    <li className='nav-item bg-white' key={i}>
                      <span className={`nav-link showpointer bg-white px-4 text-uppercase h3 mb-0 font-weight-normal ${
                        selectedTab === i
                          ? 'font-weight-bold text-primary set-border'
                          : ''
                        } `}
                        onClick={() => handleTab(i)}
                      >
                        <span>{name}</span>
                      </span>
                    </li>
                  ))}
                </ul>
                <div className='container-fluid bg-white rounded shadow'>
                  <Row>
                    <Table striped responsive hover>
                      <thead>
                        <tr>
                          {
                          table_headers.map((head, index) => (
                            <th key={index} style={{ width: '10%' }}>
                              {head}
                            </th>
                          ))
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {currentDocs.length ? (
                          currentDocs.map((data, index) => (
                            data.metadata ?
                              <tr key={index}>
                                <td>{data.metadata.plant_name}</td>
                                <td>{data.metadata.product_type}</td>
                                <td>{data.metadata.claimed_origin}</td>
                                <td>{data.metadata.collection_date}</td>
                                <td>{data.metadata.issue_date}</td>
                                <td>{data.metadata.uploaded_by}</td>
                                <td className="text-primary"><a style={{ cursor: 'pointer' }} onClick={()=>downloadDocument(data.url,data.name)}>View</a></td>
                              </tr> :
                              <tr key={index}>
                                <td>{data.supplier_name}</td>
                                <td>Finished</td>
                                <td>Cotton</td>
                                <td>{data.certificate_date}</td>
                                <td>{data.uploaded_date}</td>
                                <td>{data.uploaded_by}</td>
                                <td className="text-primary"><a style={{ cursor: 'pointer' }} onClick={()=>downloadDocument(data.url,data.name)}>View</a></td>
                              </tr>)
                          )) : (
                            <tr>
                              <td colSpan='9'>
                                <strong>Not Found</strong>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        ) : !loading && errors ? (
          <Error message={messageList.error} />
        ) : null}
      </Container>
    </React.Fragment>
  );
}
