import { api } from '../../axios-instance';
import { backendRoutes, headers } from '../../shared/utility';
import * as actionTypes from './action-types';

export const printQRPDFStart = _ => ({
  type: actionTypes.PRINT_QR_PDF_START
});
export const printQRPDFSuccess = _ => ({
  type: actionTypes.PRINT_QR_PDF_SUCCESS
});
export const printQRPDFFail = (error, msg) => ({
  type: actionTypes.PRINT_QR_PDF_FAIL,
  error,
  msg
});

export const printQRPDF = so_line_item_id => {
  return dispatch => {
    dispatch(printQRPDFStart());
    api
      .post(
        backendRoutes.printQRCodePDF(so_line_item_id),
        {},
        { ...headers(), responseType: 'blob' }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${so_line_item_id}.pdf`);
        link.click();
        link.remove();
        dispatch(printQRPDFSuccess());
      })
      .catch(error => {
        dispatch(printQRPDFFail(error, error.message));
      });
  };
};
