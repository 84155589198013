import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import * as actions from '../../store/actions/index';
import getAssetImage from '../../shared/ImageAssets';
import Loader from '../../shared/Loader/Loader';
import Error from '../../shared/Error/Error';
import './DepartmentIndex.css';
import { messageList } from '../../shared/utility';
class DepartmentIndex extends Component {
  componentDidMount() {
    this.props.onInit();
  }

  openDepartmentPage = dept => {
    this.props.history.push(`/admin/departments/${dept.id}`); // .toLowerCase().replace(/ /g, '-')}`)
  };

  render() {
    let { allDepartments, deptLoading, deptSuccess, deptError } = this.props;
    return (
      <Container fluid className='pb-5'>
        <div>
          <Row>
            <Col lg='9' md='9' sm='12' xs='12' className='text-left'>
              <span className='h1 mb-2 text-primary font-weight-bold text-capitalize'>
                All Departments
              </span>
            </Col>
          </Row>
          {deptLoading && !deptSuccess? (
            <Loader />
          ) : !deptLoading && deptSuccess ? (
            <div className='grid-container mt-4'>
              {allDepartments.length
                ? allDepartments.map((dept, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          this.openDepartmentPage(dept);
                        }}
                        className='grid-item'
                      >
                        <Card className=' showpointer custom-card '>
                          <CardBody>
                            <Row>
                              <Col className='custom-size p-0 pl-4 text-left'>
                                <span className='mb-0 p-0'>{dept.name}</span>
                              </Col>
                              <Col className='p-0 pr-4 text-right'>
                                <div className='icon icon-shape bg-primary text-white rounded-circle shadow'>
                                  <img
                                    id='dept-image'
                                    src={getAssetImage(
                                      dept.name.toLowerCase().replace(/ /g, '_')
                                    )}
                                    alt='dept'
                                    width='60px'
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* <p className="mt-3 mb-0 text-muted text-sm">
                                                        <span className="text-success mr-2">
                                                            <i className="fa fa-arrow-up" /> 3.48%
                                                        </span>{" "}
                                                        <span className="text-nowrap">Since last month</span>
                                                    </p> */}
                          </CardBody>
                        </Card>
                      </div>
                    );
                  })
                : null}
            </div>
          ) : !deptLoading && deptError ? (
            <Error message={messageList.error} />
          ) : null}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  allDepartments: state.departments.allDepartments,
  deptLoading: state.departments.loading,
  deptError: state.departments.errors,
  deptSuccess: state.departments.success,
  deptMsg: state.departments.msg
});
const mapDispatchToProps = dispatch => ({
  onInit: () => dispatch(actions.init())
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentIndex);
